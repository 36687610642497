import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import styles from "./showFields.module.css";

function Email({ value }) {
  return !isEmpty(value) ? (
    <a
      className={styles.Email}
      href={`mailto:${value}`}
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      {value}
    </a>
  ) : (
    <span className={styles.Email} />
  );
}
Email.propTypes = {
  value: PropTypes.string,
};

export default Email;
