import React from "react";
import PropTypes from "prop-types";
import { castArray, map, compact } from "lodash";

import styles from "./showFields.module.css";

export const defaultImageUrl = (id, size) =>
  `/api/storage/images/${id}/get/${size}`;

function Image({ value }) {
  return (
    <div className={styles.Image}>
      {map(
        compact(castArray(value)),
        ({ id, name, imageUrl, thumbnailUrl }) => (
          <a
            key={id}
            href={imageUrl || defaultImageUrl(id, "1920x1200")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={thumbnailUrl || defaultImageUrl(id, "200x200")}
              alt={name}
            />
          </a>
        ),
      )}
    </div>
  );
}
const imageShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  imageUrl: PropTypes.string, // for storybook
  thumbnailUrl: PropTypes.string, // for storybook
});
Image.propTypes = {
  value: PropTypes.oneOfType([PropTypes.arrayOf(imageShape), imageShape]),
};

export default Image;
