import React from "react";

import createInlineStyleButton from "components/shared/markdownEditor/editorPlugins/buttons/createInlineStyleButton";

export const BoldButton = createInlineStyleButton({
  style: "BOLD",
  title: () => I18n.t("js.composer.editor.buttons.bold"),
  content: <i className="fa fa-bold" />,
});

export const ItalicButton = createInlineStyleButton({
  style: "ITALIC",
  title: () => I18n.t("js.composer.editor.buttons.italic"),
  content: <i className="fa fa-italic" />,
});

export const UnderlineButton = createInlineStyleButton({
  style: "UNDERLINE",
  title: () => I18n.t("js.composer.editor.buttons.underline"),
  content: <i className="fa fa-underline" />,
});

export const StrikeButton = createInlineStyleButton({
  style: "STRIKETHROUGH",
  title: () => I18n.t("js.composer.editor.buttons.strikethrough"),
  content: <i className="fa fa-strikethrough" />,
});

export const MarkButton = createInlineStyleButton({
  style: "MARK",
  title: () => I18n.t("js.composer.editor.buttons.mark"),
  content: <i className="fa fa-pencil-square" />,
});

export const CodeButton = createInlineStyleButton({
  style: "CODE",
  title: () => I18n.t("js.composer.editor.buttons.code"),
  content: <i className="fa fa-code" />,
});
