import { trim, reduce } from "lodash";
// Parser for custom text colors
// <span style="color:red;">Text</span>

// 0 => whole token
// 1 => span open tag
// 2 => style
// 3 => text
// 4 => span close tag
const regexp = /^(<span (?:.*?)style="(.*?)"(?:.*?)>)(.*?)(<\/span>)/i;

const cssStyle = (state, silent) => {
  const start = state.pos;
  const posMax = state.posMax;

  const match = regexp.exec(state.src.slice(start));

  if (!match) return false;
  if (silent) return false;

  const styles = reduce(
    match[2].split(";"),
    (styles, style) => {
      const styleTuple = style.split(":");
      styles[trim(styleTuple[0])] = trim(styleTuple[1]);
      return styles;
    },
    {},
  );

  if (!styles.color) return false;

  // adjust state position to match text
  state.pos = start + match[1].length;
  state.posMax = state.pos + match[3].length;

  if (styles.color) {
    state.push({ type: `color_${styles.color}_open`, level: state.level++ });
  }

  state.parser.tokenize(state);

  if (styles.color) {
    state.push({ type: `color_${styles.color}_close`, level: --state.level });
  }

  // adjust state position after token
  state.pos = start + match[0].length;
  state.posMax = posMax;

  return true;
};

const cssStyleParser = (md) => {
  md.inline.ruler.push("cssStyle", cssStyle);
};

export default cssStyleParser;
