import React from "react";
import { get } from "lodash";
import FileIcon from "components/shared/FileIcon";

import UploadBar from "./UploadBar";
import MarkdownTranslation from "../../MarkdownTranslation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

interface UploadedFileTypes {
  file: {
    id: string;
    clientId: string;
    state: "uploading" | "volatile" | "published" | "failed" | "quarantine";
    name: string;
    progress: number;
    status: number;
  };

  remove(...args: unknown[]): unknown;
}

function UploadedFile({ file, remove }: UploadedFileTypes) {
  const handleRemoveClick = (e) => {
    e.preventDefault();
    const criteria = file.id ? { id: file.id } : { clientId: file.clientId };
    remove(criteria);
  };

  return (
    <div className="upload-status">
      <div className="upload-progress">
        <UploadBar progress={file.progress} state={file.state} />
      </div>
      <div className="upload-controls flex items-center">
        <div className="flex gap-2 items-center truncate">
          {file.state == "quarantine" ? (
            <>
              <FontAwesomeIcon
                icon={regular("virus")}
                className={"fa-lg mr-2"}
              />
              <span className="font-bold">
                {I18n.t("js.files.uploader.error_quarantine")}
              </span>
              <span className="text-muted truncate">{file.name}</span>
            </>
          ) : (
            <>
              <FileIcon extension={get(file, "extension")} />
              <span className="text-muted truncate">{file.name}</span>
            </>
          )}
        </div>

        {file.status ? (
          <MarkdownTranslation
            translation={`js.files.uploader.error_code_${file.status}`}
          />
        ) : null}

        <a
          href="#remove"
          className="remove btn"
          onClick={handleRemoveClick}
          data-id={file.id}
          title={I18n.t("js.remove")}
        >
          <FontAwesomeIcon icon={solid("circle-x")} className={"fa-sm"} />
        </a>
      </div>
    </div>
  );
}

export default UploadedFile;
