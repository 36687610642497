import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const FolderIcons = {
  folder: regular("folder"),
  root_folder: regular("users"),
  activitystream_folder: regular("earth-europe"),
};

function FolderIcon({ type, muted }) {
  return (
    <FontAwesomeIcon
      icon={FolderIcons[type || "folder"]}
      className={classNames(`icons-folder`, {
        "text-muted": muted,
      })}
    />
  );
}
FolderIcon.propTypes = { type: PropTypes.string, muted: PropTypes.bool };

export default FolderIcon;
