import { compose, withHandlers, withProps } from "recompose";
import { RichUtils } from "draft-js";

import ToolbarButton from "components/shared/markdownEditor/editorPlugins/buttons/ToolbarButton";

export default ({ style, content, title, toggleStyle, isActive }) => {
  return compose(
    withProps(() => ({
      title,
      style,
      children: content,
    })),
    withHandlers({
      onClick: (props) => (e) => {
        e.preventDefault();
        if (toggleStyle) {
          toggleStyle(props);
        } else {
          props.setEditorState(
            RichUtils.toggleInlineStyle(props.getEditorState(), style),
          );
        }
      },
      isDisabled: () => () => !document.getSelection().toString(),
      isActive: (props) => () => {
        if (!props.getEditorState) {
          return false;
        }

        if (isActive) {
          return isActive(props);
        } else {
          return props.getEditorState().getCurrentInlineStyle().has(style);
        }
      },
    }),
  )(ToolbarButton);
};
