import { createApiAction } from "helpers/api";
import { action } from "helpers/actions";

const NAMESPACE = "administration/widgetbar";

export const ADD_OR_MOVE_WIDGET = NAMESPACE + "/ADD_WIDGET";
export const REMOVE_WIDGET = NAMESPACE + "/REMOVE_WIDGET";
export const CHANGE_WIDGET_VALUE = NAMESPACE + "/CHANGE_VALUE";
export const SELECT_WIDGET = NAMESPACE + "/SELECT_WIDGET";
export const FETCH_WIDGETBAR = NAMESPACE + "/FETCH_WIDGETBAR";
export const UPDATE_WIDGETBAR = NAMESPACE + "/UPDATE_WIDGETBAR";
export const SET_GROUP_ID = NAMESPACE + "/SET_GROUP_ID";
export const RESET_SUCCESSFUL_SAVE = NAMESPACE + "/RESET_SUCCESSFUL_SAVE";
export const RESET_ERRORS = NAMESPACE + "/RESET_ERRORS";
export const FETCH_WIDGETBARS = NAMESPACE + "/FETCH_WIDGETBARS";
export const FETCH_CALENDARS = NAMESPACE + "/FETCH_CALENDARS";
export const SET_IDENTIFIER = NAMESPACE + "/SET_IDENTIFIER";

export const addOrMoveWidget = (data, index) =>
  action(ADD_OR_MOVE_WIDGET, { data, index });
export const removeWidget = () => action(REMOVE_WIDGET);
export const selectWidget = (data) => action(SELECT_WIDGET, { data });
export const setGroupId = (data) => action(SET_GROUP_ID, { data });
export const resetErrors = (data) => action(RESET_ERRORS, { data });
export const resetSuccessfulSave = (data) =>
  action(RESET_SUCCESSFUL_SAVE, { data });
export const changeValue = (propertyKey, data) =>
  action(CHANGE_WIDGET_VALUE, { propertyKey, data });
export const setIdentifier = (data) => action(SET_IDENTIFIER, { data: data });

export const fetchWidgetbar = createApiAction({
  method: "GET",
  endpoint: ({ groupId, identifier }) => {
    const context = groupId ? `/groups/${groupId}` : "";

    return `${context}/administration/widgetbars/${identifier}.json`;
  },
  baseType: FETCH_WIDGETBAR,
});

export const updateWidgetbar = createApiAction({
  method: "PUT",
  endpoint: ({ groupId, identifier }) => {
    const context = groupId ? `/groups/${groupId}` : "";

    return `${context}/administration/widgetbars/${identifier}.json`;
  },
  baseType: UPDATE_WIDGETBAR,
});

export const fetchWidgetbars = createApiAction({
  method: "GET",
  endpoint: ({ groupId }) => {
    const context = groupId ? `/groups/${groupId}` : "";

    return `${context}/administration/widgetbars.json`;
  },
  baseType: FETCH_WIDGETBARS,
});

export const fetchCalendars = createApiAction({
  method: "GET",
  endpoint: ({ groupId }) => {
    const context = groupId ? `/groups/${groupId}` : "";

    return `${context}/administration/widgetbars/calendars.json`;
  },
  baseType: FETCH_CALENDARS,
});
