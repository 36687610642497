import { map } from "lodash";
import CustomConfigurator from "../../../CustomConfigurator";

if (!CustomConfigurator.existsDefault("markdownEditor")) {
  // Set default colors
  CustomConfigurator.setDefault(
    "markdownEditor.colors",
    map(
      [
        "red",
        "green",
        "blue",
        "orange",
        "goldenrod",
        "indigo",
        "purple",
        "violet",
        "hotpink",
        "olive",
        "mediumseagreen",
        "limegreen",
        "dimgrey",
      ],
      (color) => ({
        label: () => I18n.t(`js.composer.editor.colors.${color}`),
        style: color,
      }),
    ),
  );

  // Set default buttons
  CustomConfigurator.setDefault("markdownEditor.buttons", [
    "BoldButton",
    "ItalicButton",
    "UnderlineButton",
    "StrikeButton",
    "Separator",
    "FontColorsButton",
    "MarkButton",
    "CodeButton",
    "Separator",
    "HeadlinesButton",
    "UnorderedListButton",
    "OrderedListButton",
    "BlockquoteButton",
    "Separator",
    "LinkButton",
  ]);
}
