import React, { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { map, castArray, isArray, compact, debounce, groupBy } from "lodash";
import { Field } from "redux-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";

import styles from "./editFields.module.css";
import { fetchRelationOptions } from "actions/appCreator";
import FieldError from "components/appCreator/items/form/FieldError";
import indexBy from "../../../../helpers/indexBy";

const normalizeValue = (value) => {
  if (value === null) return null; // When nothing is selected
  if (isArray(value)) return map(value, normalizeValue);

  return {
    id: value.value,
    displayValue: value.label,
    values: value.values,
    isNew: value.isNew,
  };
};

const formatValue = (state) =>
  map(compactArray(state), ({ id, displayValue, values, isNew }) => ({
    value: id,
    label: displayValue,
    values,
    isNew,
  }));

const compactArray = (arr) => compact(castArray(arr));

const formatOptionsBasedOnApps = (options, relatedApps) => {
  options = compactArray(options);
  const appDetailsById = indexBy(relatedApps, "id");
  const optionsGroupedByAppId = groupBy(options, "app_id");
  return map(optionsGroupedByAppId, (optionsInApp, app_id) => ({
    label: appDetailsById[app_id]?.name,
    options: formatValue(optionsInApp),
  }));
};

function formatCreateLabel(label) {
  return (
    <span>
      <i className="fa fa-plus" /> {label}
    </span>
  );
}

function SelectAdapter({
  input,
  options,
  multiple,
  required,
  id,
  disabled,
  allow_create_related,
  async,
  appId,
  className,
  meta: { error },
}) {
  const dispatch = useDispatch();
  const loadOptions = useMemo(
    () =>
      debounce(async (value, callback) => {
        const { payload } = await dispatch(
          fetchRelationOptions({ appId, propertyName: input.name, q: value }),
        );
        callback(formatValue(payload));
      }, 375),
    [appId, input.name],
  );

  let props = {
    ...input,
    // workaround for mobile,
    // see ticket #9930 or issue https://github.com/JedWatson/react-select/issues/2692#issuecomment-395743446
    onBlur: (e) => e.preventDefault(),
    options,
    isMulti: multiple,
    isClearable: !required,
    isDisabled: disabled,
    inputId: id,
    placeholder: I18n.t("js.apps.properties.relation.placeholder"),
    noOptionsMessage: () => I18n.t("plugins.select2.no_match"),
    className,
    styles: { menu: (base) => ({ ...base, zIndex: 999 }) },
  };

  if (allow_create_related) {
    props.onCreateOption = (value) => {
      const newValue = {
        label: value,
        isNew: true,
        value: `new_${value}`,
        values: { name: value },
      };
      input.onChange(multiple ? [...input.value, newValue] : newValue);
    };
    props.formatCreateLabel = formatCreateLabel;
  }
  if (async) {
    props.loadOptions = (value, callback) => {
      loadOptions(value, callback);
    };
  }

  let Component;

  if (async && allow_create_related) {
    Component = AsyncCreatableSelect;
  } else if (async && !allow_create_related) {
    Component = AsyncSelect;
  } else if (!async && allow_create_related) {
    Component = CreatableSelect;
  } else {
    Component = Select;
  }

  return (
    <Fragment>
      <Component {...props} classNamePrefix={"Select"} />
      {error && <FieldError error={error} />}
    </Fragment>
  );
}
SelectAdapter.propTypes = {
  input: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      app_id: PropTypes.string,
    }),
  ),
  related_apps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  allow_create_related: PropTypes.bool,
  async: PropTypes.bool,
  polymorphic: PropTypes.bool,
  loadOptions: PropTypes.func,
  meta: PropTypes.shape({ error: FieldError.propTypes.error }),
};

function RelationField({
  name,
  required,
  options,
  multiple,
  disabled,
  id,
  allow_create_related,
  async,
  appId,
  polymorphic,
  related_apps,
}) {
  return (
    <Field
      component={SelectAdapter}
      name={name}
      required={required}
      multiple={multiple}
      options={
        polymorphic
          ? formatOptionsBasedOnApps(options, related_apps)
          : formatValue(options)
      }
      disabled={disabled}
      normalize={normalizeValue}
      format={formatValue}
      id={id}
      props={{
        allow_create_related,
        className: `${styles.Relation} property-${name}`,
        async,
        appId,
      }}
    />
  );
}
RelationField.propTypes = {
  name: PropTypes.string.isRequired,
  ...SelectAdapter.propTypes,
};

export default RelationField;
