import React from "react";
import createMarkdownPlugin from "draft-js-markdown-plugin";

import CodeBlockLanguageSelect from "components/shared/markdownEditor/CodeBlockLanguageSelect";

export function renderLanguageSelect(props) {
  return <CodeBlockLanguageSelect {...props} />;
}

export default function createCustomMarkdownPlugin() {
  return createMarkdownPlugin({
    renderLanguageSelect,
    languages: {
      plain: "Text",
      bash: "Bash",
      css: "CSS",
      html: "HTML",
      js: "JavaScript",
      markdown: "Markdown",
      ruby: "Ruby",
      sql: "SQL",
    },
    customInlineMatchers: {
      BOLD: [/\*\*(.+)\*\*(\s)$/g],
      ITALIC: [/\*(.+)\*(\s)$/g],
      UNDERLINE: [/_(.+)_(\s)$/g],
      MARK: [/==(.+)==(\s)$/g],
      CODE: [/`([^`]+)`(\s)$/g],
      STRIKETHROUGH: [/~~([^~]+)~~(\s)$/g],
    },
    features: {
      inline: ["BOLD", "ITALIC", "UNDERLINE", "CODE", "STRIKETHROUGH", "MARK"],
      block: [
        "CODE",
        "header-one",
        "header-two",
        "header-three",
        "header-four",
        "header-five",
        // 'header-six',
        "ordered-list-item",
        "unordered-list-item",
        "blockquote",
      ],
    },
    entityType: {
      IMAGE: "IMAGE",
    },
  });
}
