export const REQUEST = "REQUEST";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const PROGRESS = "PROGRESS";

export function createRequestTypes(base) {
  return {
    REQUEST: `${base}/${REQUEST}`,
    SUCCESS: `${base}/${SUCCESS}`,
    FAILURE: `${base}/${FAILURE}`,
    PROGRESS: `${base}/${PROGRESS}`,
  };
}

export function action(type, payload = {}) {
  return { ...payload, type };
}

// Creates action with included REQUEST supertype
export function requestAction(type, payload = {}) {
  return action(type.REQUEST, { ...payload, superType: REQUEST });
}

// Creates action with included SUCCESS supertype
export function successAction(type, payload = {}) {
  return action(type.SUCCESS, { ...payload, superType: SUCCESS });
}

// Creates action with included FAILURE supertype
export function failureAction(type, payload = {}) {
  return action(type.FAILURE, { ...payload, superType: FAILURE });
}

// Creates action with included PROGRESS supertype
export function progressAction(type, payload = {}) {
  return action(type.PROGRESS, { ...payload, superType: PROGRESS });
}
