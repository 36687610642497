//= require ./version
import { memoize } from "lodash";

window.CKEDITOR_BASEPATH = "/static/ckeditor/";

// Loads CKEditor, but only once
const loadAndConfigureCKEditor = memoize(
  () =>
    new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "/static/ckeditor/ckeditor.js";
      script.async = true;
      script.onload = () => {
        configure();
        resolve(window.CKEDITOR);
      };
      document.body.appendChild(script);
    }),
);

export default loadAndConfigureCKEditor;

function configure() {
  if (!window.CKEDITOR) return;

  // Define changes to default configuration here.
  // For complete reference see:
  // http://docs.ckeditor.com/#!/api/CKEDITOR.config
  CKEDITOR.disableAutoInline = true;
  const config = CKEDITOR.config;

  // don't load config file via ajax
  config.customConfig = "";
  config.language = I18n.locale.split("_")[0];

  // Codesnippet config
  config.codeSnippet_theme = "default"; // doesn't work here, cause the plugin changes the default again
  config.codeSnippet_languages = {
    bash: "Bash",
    coffeescript: "CoffeeScript",
    cpp: "C++",
    cs: "C#",
    css: "CSS",
    html: "HTML",
    java: "Java",
    javascript: "JavaScript",
    json: "JSON",
    markdown: "Markdown",
    objectivec: "Objective-C",
    perl: "Perl",
    php: "PHP",
    python: "Python",
    ruby: "Ruby",
    sql: "SQL",
    vbscript: "VBScript",
    xml: "XML",
  };

  // The toolbar groups arrangement, optimized for two toolbar rows.
  config.toolbarGroups = [
    {
      name: "clipboard",
      groups: ["clipboard", "undo"],
    },
    {
      name: "editing",
      groups: ["find", "selection"],
    },
    {
      name: "links",
    },
    {
      name: "insert",
    },
    {
      name: "forms",
    },
    {
      name: "tools",
    },
    {
      name: "document",
      groups: ["mode", "document", "doctools"],
    },
    {
      name: "others",
    },
    "/",
    {
      name: "basicstyles",
      groups: ["basicstyles"],
    },
    {
      name: "paragraph",
      groups: ["list", "indent", "align", "blocks"],
    },
    {
      name: "styles",
    },
    {
      name: "colors",
    },
    {
      name: "about",
    },
  ];

  // No links with onclick please
  // Other Elements(+Attributes) can be added too
  // http://docs.ckeditor.com/#!/guide/dev_acf
  config.disallowedContent = "a[onclick]";
  config.allowedContent = true;

  // Remove some buttons provided by the standard plugins, which are
  // not needed in the Standard(s) toolbar.
  config.removeButtons = "Subscript,Superscript";

  // Set the most common block elements.
  config.format_tags = "p;h1;h2;h3;pre";

  // Simplify the dialog windows.
  config.removeDialogTabs = "image:advanced;link:advanced";
  config.extraPlugins =
    "autogrow,colorbutton,colordialog,font,justify,find,codesnippet,iframe";
  config.removePlugins = "about,scayt,specialchar,stylescombo,wsc";
  config.filebrowserImageBrowseUrl = "/system/ckeditor/images";
  config.filebrowserImageUploadUrl = "/api/storage/assets/ckeditor/images";

  // Add custom css to style content
  config.contentsCss = [
    CKEDITOR.getUrl("contents.css"),
    window.Assets["ckeditor_override.css"],
  ];

  // Modify Link Dialog to only allow null and _blank as targets
  // hook into dialogDefinition Event
  // http://ckeditor.com/latest/samples/plugins/dialog/dialog.html
  CKEDITOR.on("dialogDefinition", function (ev) {
    var dialogDefinition, editor, targetTab, targetTypeSelect;
    // if this is the link dialog
    if (ev.data.name === "link") {
      dialogDefinition = ev.data.definition;
      editor = ev.editor;
      // override target select options
      targetTab = dialogDefinition.getContents("target");
      targetTypeSelect = targetTab.get("linkTargetType");
      return (targetTypeSelect.items = [
        [editor.lang.common.notSet, "notSet"],
        [editor.lang.common.targetNew, "_blank"],
      ]);
    }
  });
}
