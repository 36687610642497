import React from "react";
import { isEmpty } from "lodash";

interface HintProps {
  hint?: string;
  className?: string;
}

function Hint({ hint, className = "" }: HintProps) {
  return isEmpty(hint) ? null : (
    <div
      className={`help-block ${className}`}
      dangerouslySetInnerHTML={{ __html: Application.markdown(hint) }}
    />
  );
}

export default Hint;
