import React from "react";
import PropTypes from "prop-types";

import GenericValueList from "./helpers/GenericValueList";

function File({ value }) {
  return (
    <GenericValueList
      values={value}
      component={(file) => (
        <a className="file-link" href={`/files/${file.id}`} title={file.name}>
          <i className="icon icons-file">
            <span className={`i-${file.extension}`}>{file.extension}</span>
          </i>
          <span className="file-name">{file.name}</span>
        </a>
      )}
    />
  );
}
File.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      extension: PropTypes.string,
    }),
  ),
};

export default File;
