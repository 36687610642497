import React from "react";
import { compose, withHandlers, withProps } from "recompose";
import { isFunction, map } from "lodash";

import ToolbarButton from "components/shared/markdownEditor/editorPlugins/buttons/ToolbarButton";
import Separator from "components/shared/markdownEditor/editorPlugins/buttons/Separator";

function Picker(props) {
  const buttons = [
    <ToolbarButton key="back" onClick={closePicker} title="Zurück">
      <i className="fa fa-chevron-left" />
    </ToolbarButton>,
    <Separator key="back-seperator" />,
  ];

  return buttons.concat(
    map(
      isFunction(props.pickerButtons)
        ? props.pickerButtons()
        : props.pickerButtons,
      (Button, i) => <Button key={i} {...props} />,
    ),
  );

  function closePicker() {
    // Call `onOverrideContent` with `undefined`, to show regular content
    props.onOverrideContent(undefined);
  }
}

export default ({ content, title, pickerButtons, isActive, isDisabled }) => {
  return compose(
    withProps(() => ({
      title,
      pickerButtons,
      children: content,
    })),
    withHandlers({
      isDisabled: (props) => () => (isDisabled ? isDisabled(props) : false),
      isActive: (props) => () => (isActive ? isActive(props) : false),
      onClick:
        ({ onOverrideContent }) =>
        () =>
          onOverrideContent((props) => (
            <div className="draftJsToolbar__toolbar__dNtBH">
              <Picker pickerButtons={pickerButtons} {...props} />
            </div>
          )),
    }),
  )(ToolbarButton);
};
