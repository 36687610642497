import React from "react";
import PropTypes from "prop-types";

import GenericValueList from "./helpers/GenericValueList";

import styles from "./showFields.module.css";

function Relation({ value, property }) {
  return (
    <GenericValueList
      values={value}
      component={({ id, displayValue }) => (
        <a
          className={styles.Relation}
          href={`/apps/${property.related_app_id}/items/${id}`}
        >
          {displayValue}
        </a>
      )}
    />
  );
}
const relationPropType = PropTypes.shape({
  id: PropTypes.string,
  displayValue: PropTypes.string,
});
Relation.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(relationPropType),
    relationPropType,
  ]),
  property: PropTypes.shape({
    related_app_id: PropTypes.string,
  }),
};

export default Relation;
