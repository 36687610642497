export default function currentMember(
  state = {},
  action: { type: string; payload: string },
) {
  switch (action.type) {
    case "currentMember/LANGUAGE/SET_LAST_USED": {
      return {
        ...state,
        last_used_translation_language: action.payload,
      };
    }
    default:
      return state;
  }
}
