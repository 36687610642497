import { get as getPath, set as setPath, isNil } from "lodash";

// This object stores static configuration and allow overrides for customers
// Can be used for all static configurations, used i.e. for:
// - MarkdownComposer: colors and toolbar buttons
// - AppointmentInvites: component lookup tables
class CustomConfigurator {
  // Constructor with singleton pattern
  constructor() {
    if (!CustomConfigurator.instance) {
      this._defaults = {};
      this._config = {};
      CustomConfigurator.instance = this;
    }

    return CustomConfigurator.instance;
  }

  // Get a config key from the internally stored config
  // @param [String,Array] path - see lodash docs for get
  get(path) {
    return getPath(this._config, path, getPath(this._defaults, path));
  }

  // Set a config key to the internally stored config
  // @param [String,Array] path - see lodash docs for set
  // @param [any] value - value that should be set
  set(path, value) {
    setPath(this._config, path, value);
  }

  // Set a config key as default value for the internally stored config
  // @param [String,Array] path - see lodash docs for set
  // @param [any] value - default value that should be set
  setDefault(path, value) {
    setPath(this._defaults, path, value);
  }

  // Check if something exists (!isEmpty) for the given configuration key
  // @param [String,Array] path - see lodash docs for get
  exists(path) {
    return !isNil(getPath(this._config, path));
  }

  // Check if some defaults exists (!isEmpty) for the given configuration key
  // @param [String,Array] path - see lodash docs for get
  existsDefault(path) {
    return !isNil(getPath(this._defaults, path));
  }
}

// Create instance, freeze it and export it
if (!window.CustomConfigurator) {
  window.CustomConfigurator = new CustomConfigurator();
  Object.freeze(window.CustomConfigurator);
}

export default window.CustomConfigurator;
