import type { SubmitHandler } from "redux-form";
import React from "react";
import { reduxForm } from "redux-form";
import classNames from "classnames";
import FormActions from "./FormActions";

export interface FormTypes {
  children?: React.ReactNode;
  handleSubmit?: SubmitHandler<
    Record<PropertyKey, unknown>,
    Record<PropertyKey, unknown>,
    string
  >;
  pristine?: boolean;
  submitting?: boolean;

  reset?(): void;

  initialValues?: Record<PropertyKey, unknown>;

  onSubmit(values: any, dispatch: any): unknown | Promise<void>;

  onSubmitSuccess?(...args: unknown[]): unknown;

  onSubmitFail?(error: Error): unknown;

  enableReinitialize?: boolean;

  renderChildren?(...props: unknown[]): React.ReactNode;

  className?: string;
  processing?: boolean;
  classNameFormActions?: string;
  submitText?: string;
  submitIcon?: string;
  allowSaveWithoutChange?: boolean;

  onCancel?(...args: unknown[]): unknown;

  fullWidth?: boolean;
  noActions?: boolean;
  form?: string;
}

function Form({
  children,
  handleSubmit,
  pristine,
  submitting = false,
  reset,
  renderChildren,
  className,
  processing = false,
  classNameFormActions,
  submitText,
  submitIcon,
  allowSaveWithoutChange,
  onCancel,
  noActions = false,
  ...otherProps
}: FormTypes) {
  return (
    <form
      onSubmit={handleSubmit}
      className={classNames("form-horizontal", className)}
    >
      {renderChildren ? renderChildren(otherProps) : children}
      {noActions ? null : (
        <FormActions
          processing={processing}
          className={classNameFormActions}
          pristine={pristine && !allowSaveWithoutChange}
          submitting={submitting}
          reset={reset}
          submitText={submitText}
          submitIcon={submitIcon}
          onCancel={onCancel}
        />
      )}
    </form>
  );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const ReduxForm = reduxForm({})(Form);

export default ReduxForm as unknown as (props: FormTypes) => React.ReactElement;
