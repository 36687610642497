import { put, take, call, select, actionChannel } from "redux-saga/effects";
import { END, eventChannel, buffers } from "redux-saga";
import { map, get, find } from "lodash";
import axios from "axios";
import { change, formValueSelector } from "redux-form";

import { FILES } from "actions/files";

// Returns change action with updated file in value
const createUpdateFileValue =
  ({ form, inputName, clientId }) =>
  (value, file) => {
    const newFileValues = map(value, (fileValue) =>
      fileValue.clientId === clientId ? { ...fileValue, ...file } : fileValue,
    );
    return change(form, inputName, newFileValues);
  };

// Returns true if file identified by given clientId is not present in value
const fileWasRemoved = (value, { clientId }) =>
  find(value, { clientId }) === undefined;

/*
Manages the upload of a single file
@param {function} postWithProgress - function which performs the upload, DI for storybook
@param {File} file
@param {storageDirectory} string - "images" or "files"
@param {form} string - name of the form where we are uploading
@param {inputName} string - name of the field where we are uploading
 */
export function* fileUploader(
  postWithProgress,
  { file, clientId, storageDirectory, form, inputName },
) {
  const data = new FormData();
  data.append("file", file);
  data.append("name", file.name);
  data.append("type", file.type);
  data.append("client_size", file.size); // for debugging of #897
  data.append("external_id", clientId);

  // This creates a upload channel which emits the different
  // callbacks and promises in an action like style.
  const channel = postWithProgress(`/api/storage/${storageDirectory}`, data);

  const updateFileValue = createUpdateFileValue({ form, inputName, clientId });

  while (true) {
    const emitterResult = yield take(channel);

    const value = yield select(formValueSelector(form), inputName);
    if (fileWasRemoved(value, { clientId })) {
      channel.close();
      return;
    }

    switch (emitterResult.type) {
      case "PROGRESS": {
        yield put(updateFileValue(value, { progress: emitterResult.progress }));
        break;
      }
      case "SUCCESS": {
        yield put(
          updateFileValue(value, {
            ...get(emitterResult, ["result", "data"]),
            progress: 100,
          }),
        );
        yield put({
          type: FILES.UPLOAD.SUCCESS,
          payload: get(emitterResult, ["result", "data"]),
        });
        break;
      }
      case "FAILURE": {
        yield put(
          updateFileValue(value, {
            state: "failed",
            status: emitterResult.error.response.status,
          }),
        );
        yield put({ type: FILES.UPLOAD.FAILURE, error: emitterResult.error });
        break;
      }
    }
  }
}

// Creates cancelable event channel where the following events are emitted:
// - { type: "PROGRESS", progress: 96.2 }
// - { type: "SUCCESS", result: { id: 123, ... } } // result = api response json
// - { type: "FAILURE", error: ApiError }
const defaultPostWithProgress = (url, data) =>
  eventChannel((emit) => {
    const controller = new AbortController();
    const uploader = axios.post(url, data, {
      signal: controller.signal,
      onUploadProgress: ({ loaded, total }) => {
        emit({ type: "PROGRESS", progress: (loaded * 100) / total });
      },
    });

    uploader
      .then((result) => {
        emit({ type: "SUCCESS", result });
        emit(END);
      })
      .catch((e) => {
        emit({ type: "FAILURE", error: e });
        emit(END);
      });

    return () => {
      controller.abort();
    };
  });

// Saga to manage file uploads
// postWithProgress function is dependency injectable (i.e. for storybook)
// and defaults to defaultPostWithProgress
export default function* filesSaga(options = {}) {
  const postWithProgress = options.postWithProgress || defaultPostWithProgress;

  const uploadRequestChannel = yield actionChannel(
    FILES.UPLOAD.REQUEST,
    buffers.expanding(10),
  );

  // can be replaced with takeLeading after upgrading redux-saga
  while (true) {
    const action = yield take(uploadRequestChannel);
    yield call(fileUploader, postWithProgress, action);
  }
}
