import { stringify } from "query-string";
import { omitBy, isNil, isEmpty } from "lodash";
import { createApiAction } from "helpers/api";
import { useQuery } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

export const fetchMembersAndGroups = createApiAction({
  method: "GET",
  baseType: "FETCH_MEMBERS_AND_GROUPS",
  endpoint: (input) => `/directory?${stringify(input)}`,
});

export const fetchMemberships = createApiAction({
  baseType: "MEMBERS",
  endpoint: ({ groupSlug, includeConsumers, q, excludeGroupMembersOf }) => {
    const params = omitBy(
      {
        q: isEmpty(q) ? null : q + "*",
        include_consumers: includeConsumers,
        exclude_group_members_of: excludeGroupMembersOf,
      },
      isNil,
    );

    return [
      groupSlug ? `/groups/${groupSlug}` : null,
      `/members?`,
      stringify(params),
    ].join("");
  },
});

export const fetchMembership = createApiAction({
  method: "GET",
  endpoint: ({ membershipId }) => `/api/v1/profiles/${membershipId}`,
  baseType: `MEMBER`,
});

export const updateMembership = createApiAction({
  method: "PUT",
  endpoint: ({ membershipId }) => `/members/${membershipId}`,
  baseType: "MEMBER/UPDATE",
});

export const fetchMembershipSettings = createApiAction({
  method: "GET",
  endpoint: ({ membershipId }) => `/members/${membershipId}/settings`,
  baseType: `MEMBER/SETTINGS`,
});

export const updateMembershipSettings = createApiAction({
  method: "PUT",
  endpoint: ({ membershipId }) => `/members/${membershipId}/settings`,
  baseType: `MEMBER/SETTINGS/UPDATE`,
});

// For messages module
export function useFetchBaseMembershipData(membershipId, options = {}) {
  return useQuery(
    ["memberships", membershipId],
    () => (membershipId ? fetchApi(`/members/${membershipId}`) : null),
    options,
  );
}
