import { reduce } from "lodash";

import { colorStyle } from "components/shared/markdownEditor/editorPlugins/buttons/FontColorsButton";
import CustomConfigurator from "../../../CustomConfigurator";

const calculateColorMap = () =>
  reduce(
    CustomConfigurator.get("markdownEditor.colors"),
    (result, { style }) => {
      const styleEntity = colorStyle(style);
      result[styleEntity] = { color: style };
      return result;
    },
    {},
  );

const createStyleMap = () =>
  Object.assign(
    {
      CODE: {
        fontFamily: 'Monaco, Menlo, Consolas, "Courier New", monospac',
        fontSize: "11px",
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
        padding: "2px 4px",
        borderRadius: "3px",
        color: "#d14",
        backgroundColor: "#f7f7f9",
        border: "1px solid #e1e1e8",
      },
      SUPERSCRIPT: { fontSize: "0.6em", verticalAlign: "super" },
      MARK: { backgroundColor: "yellow" },
    },
    calculateColorMap(),
  );

export default createStyleMap;
