import React from "react";
import { EditorState, SelectionState, Modifier } from "draft-js";
import { isEmpty, trim } from "lodash";
import { getSelectionEntity, getEntityRange } from "draftjs-utils";

import ToolbarButton from "components/shared/markdownEditor/editorPlugins/buttons/ToolbarButton";

export const addLinkEntity = (editorState, url) => {
  // Create link entity
  const newContentState = editorState
    .getCurrentContent()
    .createEntity("LINK", "MUTABLE", {
      href: url,
      url: url,
      title: url,
    });

  // Get key for newly created link entity
  const entityKey = newContentState.getLastCreatedEntityKey();

  // Apply entity and push new editor state
  return EditorState.push(
    editorState,
    Modifier.applyEntity(
      newContentState,
      editorState.getSelection(),
      entityKey,
    ),
    "apply-entity",
  );
};

export const removeLinkEntity = (editorState, currentEntityKey) => {
  // Find entity range
  const entityRange = getEntityRange(editorState, currentEntityKey);
  const entitySelection = SelectionState.createEmpty(
    editorState.getSelection().getAnchorKey(),
  ).merge({
    anchorOffset: entityRange.start,
    focusOffset: entityRange.end,
  });

  // Remove entity from whole entity range and push new editor state
  return EditorState.push(
    editorState,
    Modifier.applyEntity(
      editorState.getCurrentContent(),
      entitySelection,
      null, // <- to remove entity
    ),
    "apply-entity",
  );
};

export const updateLinkEntity = (editorState, currentEntityKey, url) => {
  // Replace data in the existing entity and push new editor state
  return EditorState.push(
    editorState,
    editorState.getCurrentContent().replaceEntityData(currentEntityKey, {
      href: url,
      url: url,
      title: url,
    }),
    "apply-entity",
  );
};

interface LinkButtonTypes {
  getEditorState(): EditorState;
  setEditorState(val: EditorState): void;
}

export default function LinkButton(props: LinkButtonTypes) {
  return (
    <ToolbarButton
      {...props}
      title={I18n.t("js.composer.editor.buttons.link")}
      isActive={isActive}
      isDisabled={isDisabled}
      onClick={onClick}
    >
      <i className="fa fa-link" />
    </ToolbarButton>
  );

  function isActive() {
    const editorState = props.getEditorState();
    const currentContent = editorState.getCurrentContent();

    const currentEntityKey = getSelectionEntity(editorState);

    if (currentEntityKey) {
      const currentEntity = currentContent.getEntity(currentEntityKey);
      return currentEntity.getType() === "LINK";
    }

    return false;
  }

  function isDisabled() {
    const editorState = props.getEditorState();

    // never disable if an entity exits at selection
    if (getSelectionEntity(editorState)) {
      return false;
    }

    return editorState.getSelection().isCollapsed();
  }

  function onClick() {
    const editorState = props.getEditorState();
    const currentContent = editorState.getCurrentContent();

    let url: string | null = "";
    // Check if current entity exists and is already a link
    // Get the link as default value for prompt, to edit the link
    const currentEntityKey = getSelectionEntity(editorState);
    type currentEntity = ReturnType<typeof currentContent.getEntity>;
    let currentEntity: null | currentEntity = null;
    if (currentEntityKey) {
      currentEntity = currentContent.getEntity(currentEntityKey);
    }

    function formatUrl(url: string | undefined): string | null {
      if (!url) return null;
      if (!url.includes(".")) return url; // for anchors
      if (/https?:\/\//.exec(url as string)) return url;

      return `https://${url}`;
    }

    function changeEditorState(url: string | null) {
      // Return if url is null or
      // url for entity hasn't changed
      if (
        url === null ||
        // Same problem as in L128
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (currentEntityKey && (currentEntity as any).data.url === url)
      ) {
        return;
      }

      let newEditorState = editorState;

      if (currentEntityKey) {
        // Entity already exists, update or delete it
        if (isEmpty(trim(url))) {
          // Remove link entity, url is empty
          newEditorState = removeLinkEntity(editorState, currentEntityKey);
        } else {
          // Otherwise, update entity with new url
          newEditorState = updateLinkEntity(editorState, currentEntityKey, url);
        }
      } else {
        // No entity exists, create it
        newEditorState = addLinkEntity(editorState, url);
      }

      // Set new EditorState with forced selection to the original user selection
      props.setEditorState(
        EditorState.forceSelection(newEditorState, editorState.getSelection()),
      );
    }

    if (
      currentEntityKey &&
      (currentEntity as currentEntity).getType() === "LINK"
    ) {
      // Maybe added by a plugin?
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      url = (currentEntity as any).data.url;
    }

    // Ask for url
    window.bridge?.prompt(
      I18n.t("js.composer.editor.buttons.link_prompt"),
      url as string, // default (to edit)
      (url?: string) => changeEditorState(formatUrl(url) || ""),
    );
  }
}
