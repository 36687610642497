import PropTypes from "prop-types";
import React from "react";
import { isEmpty } from "lodash";

export const profileFieldAvailable = ({ detectionProfileField }) =>
  !isEmpty(Tixxt.currentNetwork?.get("detection_profile_field")) &&
  !isEmpty(detectionProfileField);

export const showNameWithProfileField = ({
  name,
  detectionProfileField,
  isExternal,
}) => {
  let result = name;

  if (profileFieldAvailable({ detectionProfileField })) {
    result += ` (${detectionProfileField})`;
  }

  if (isExternal) {
    result += ` (${I18n.t("js.external_member.badge")})`;
  }

  return result;
};

export function ExternalMemberBadge() {
  return (
    <>
      <span
        className="badge bg-gray-100 ml-2 text-muted"
        data-bs-toggle={"tooltip"}
        title={I18n.t("js.directory.member_row.external")}
      >
        {I18n.t("js.external_member.badge")}
      </span>
    </>
  );
}

export function NameWithProfileField({
  name,
  detectionProfileField,
  isExternal,
}) {
  return (
    <>
      {name}
      {profileFieldAvailable({ detectionProfileField }) ? (
        <>
          {" "}
          <span className="detection-profile-field">
            ({detectionProfileField})
          </span>
        </>
      ) : null}
      {isExternal ? <ExternalMemberBadge /> : null}
    </>
  );
}

NameWithProfileField.propTypes = {
  name: PropTypes.string,
  detectionProfileField: PropTypes.string,
  isExternal: PropTypes.bool,
};
