import React from "react";
import PropTypes from "prop-types";

function FieldError({ error }) {
  return <span className="text-danger">{error}</span>;
}
FieldError.propTypes = {
  error: PropTypes.string,
};

export default FieldError;
