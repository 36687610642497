import { createSelector } from "reselect";
import { get, mapValues, sortBy, orderBy, groupBy, pick, find } from "lodash";
import { Group, State } from "../@types";

export const networkSelector = (state: State) => state.network;

export const selectNetworkId = (state) => state.network.id;

export const networkNameSelector = (state) => get(state, "network.name");
export const selectNetworkMAUrlScheme = (state) =>
  get(networkSelector(state), ["config", "mobile_app", "url_scheme"]);

export const networkAdminSelector = (state) =>
  get(networkSelector(state), ["can", "administrate"]);

export const currentMemberSelector = (state: State) =>
  get(state, "currentMember");

export const groupCategoriesSelector = createSelector(
  [networkSelector],
  (network: State["network"]) =>
    orderBy(get(network, "group_categories"), "order", "asc"),
);

export const groupCategorySelector = (state, slug) => {
  const groupCategories = groupCategoriesSelector(state);
  return find(groupCategories, ["slug", slug]);
};
export const groupCategoryFromIdSelector = (state, id) => {
  const groupCategories = groupCategoriesSelector(state);
  return find(groupCategories, ["id", id]);
};
const groupSorter = (groups) =>
  sortBy(groups, [
    ({ active_main_group }) => (active_main_group ? 0 : 1),
    ({ name }) => name.toLowerCase(),
  ]);

type StateGroups = {
  bySlug: { [key: string]: Group[] };
  myGroupSlugs: string[];
};

export const myGroupsByCategoryIdSelector = createSelector(
  (state: { groups: StateGroups }) => state.groups,
  (groups: StateGroups) => {
    const myGroups = pick(groups.bySlug, groups.myGroupSlugs);
    return mapValues(groupBy(myGroups, "category_id"), groupSorter);
  },
);

export const groupSelector = (state, groupSlug) =>
  state.groups.bySlug[groupSlug];

export const fetchGroupRequestSelector = (state) =>
  state.groups.fetchGroupRequest;

export const groupDefaultsSelector = (state) => state.groups.groupDefaults;
