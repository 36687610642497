import { combineReducers } from "redux";
import { filter } from "lodash";

import { createApiResultReducer } from "helpers/api";
import {
  fetchLegalTexts,
  fetchLegalText,
  deleteLegalText,
} from "actions/administration/legalTexts";

const legalTextsRequestReducer = createApiResultReducer(fetchLegalTexts, {
  resetOnRequest: true,
});

const reducer = combineReducers({
  legalTextsRequest: (state, action) => {
    if (action.type === deleteLegalText.SUCCESS) {
      console.log("DELETE", state.data, action.meta.legalTextId);
      return {
        ...state,
        data: filter(state.data, ({ id }) => id !== action.meta.legalTextId),
      };
    }
    return legalTextsRequestReducer(state, action);
  },
  legalTextRequest: createApiResultReducer(fetchLegalText, {
    resetOnRequest: true,
  }),
});

export default reducer;
