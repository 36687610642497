import React from "react";
import createBlockStyleButton from "components/shared/markdownEditor/editorPlugins/buttons/createBlockStyleButton";

export const UnorderedListButton = createBlockStyleButton({
  blockType: "unordered-list-item",
  title: () => I18n.t("js.composer.editor.buttons.unordered_list"),
  content: <i className="fa fa-list-ul" />,
});

export const OrderedListButton = createBlockStyleButton({
  blockType: "ordered-list-item",
  title: () => I18n.t("js.composer.editor.buttons.ordered_list"),
  content: <i className="fa-regular fa-list-ol" />,
});

export const BlockquoteButton = createBlockStyleButton({
  blockType: "blockquote",
  title: () => I18n.t("js.composer.editor.buttons.blockquote"),
  content: <i className="fa fa-quote-right" />,
});

export const CodeBlockButton = createBlockStyleButton({
  blockType: "code-block",
  title: () => I18n.t("js.composer.editor.buttons.code_block"),
  content: <i className="fa-regular fa-file-code" />,
});
