import { createApiAction } from "helpers/api";

export const fetchLegalTexts = createApiAction({
  method: "GET",
  endpoint: () => `/administration/legal_texts`,
  baseType: `administration/FETCH_LEGAL_TEXTS`,
});

export const fetchLegalText = createApiAction({
  method: "GET",
  endpoint: ({ legalTextId }) => `/administration/legal_texts/${legalTextId}`,
  baseType: `administration/FETCH_LEGAL_TEXT`,
});

export const updateLegalText = createApiAction({
  method: "PUT",
  endpoint: ({ legalTextId }) => `/administration/legal_texts/${legalTextId}`,
  baseType: `administration/UPDATE_LEGAL_TEXT`,
});

export const createLegalText = createApiAction({
  method: "POST",
  endpoint: () => `/administration/legal_texts`,
  baseType: `administration/CREATE_LEGAL_TEXT`,
});

export const deleteLegalText = createApiAction({
  method: "DELETE",
  endpoint: ({ legalTextId }) => `/administration/legal_texts/${legalTextId}`,
  baseType: `administration/DELETE_LEGAL_TEXT`,
});
