import { useMemo, useState } from "react";
import { includes, filter, uniq, map, findIndex, compact } from "lodash";

import useWindowEvent from "./useWindowEvent";

// Hook to handle item batch selection (select multiple items with shift key pressed)
//
// @param [Array<String>] itemIds the given item ids
// @param [Array<String>] selectedIds the selected item ids
// @param [Function] selectIds function to select the ids (e.g. save in component state or whatever)
//
// @return [Function] onSelect returns the select function to pass to e.g. an item row or something (needs item id)
//
// @example
//    const onSelectFile = useItemBatchSelector(fileIds, selectedFileIds, selectFileIds);
//
const useItemBatchSelection = (ids, selectedIds, selectIds) => {
  const [shiftIsPressed, setShiftIsPressed] = useState(false);
  const [metaIsPressed, setMetaPressed] = useState(false);
  const [firstIdSelected, setFirstIdSelected] = useState(null);

  // main select function
  //
  // @params [String] id
  //
  const { onSelect, onKeyDown, onKeyUp } = useMemo(() => {
    const onKeyDown = (e) => {
      switch (e.key) {
        case "Shift":
          setShiftIsPressed(true);
          break;
        case "Meta":
          setMetaPressed(true);
          break;
        default:
          break;
      }
    };

    const onKeyUp = (e) => {
      switch (e.key) {
        case "Shift":
          setShiftIsPressed(false);
          break;
        case "Meta":
          setMetaPressed(false);
          break;
        default:
          break;
      }
    };

    // adds item id to selected item ids
    const addItemId = (id) => {
      includes(selectedIds, id)
        ? selectIds(filter(selectedIds, (fileId) => fileId !== id))
        : selectIds([...selectedIds, id]);
    };

    const onSelect = (id, selectSingle = false) => {
      const itemIds = ids.current;
      // determine batch range if item is selected while shift key is pressed
      if (shiftIsPressed) {
        const firstIndex = findIndex(itemIds, (id) => id === firstIdSelected);
        const lastIndex = findIndex(itemIds, (itemId) => itemId === id);

        const newFileIds = map(itemIds, (itemId, i) =>
          (i >= firstIndex && i <= lastIndex) ||
          (i <= firstIndex && i >= lastIndex)
            ? itemId
            : null,
        );

        setFirstIdSelected(id);
        selectIds(uniq([...selectedIds, ...compact(newFileIds)]));
      } else {
        // select single item if only single item should be selected an meta key is not pressed
        // e.g. on row click instead of checkbox
        selectSingle && !metaIsPressed ? selectIds([id]) : addItemId(id);
      }

      setFirstIdSelected(id);
    };

    return { onSelect, onKeyDown, onKeyUp };
  }, [selectedIds.join(","), shiftIsPressed, metaIsPressed, firstIdSelected]);

  useWindowEvent("keydown", onKeyDown);
  useWindowEvent("keyup", onKeyUp);

  return onSelect;
};

export default useItemBatchSelection;
