import { get } from "lodash";

export const selectFolderProfilesEnabled = (state) =>
  get(state, ["network", "config", "folder_profiles", "enabled"]);

export const selectFileProfilesEnabled = (state) =>
  get(state, ["network", "config", "file_profiles", "enabled"]);

export const selectFileProfileColumnFields = (state) =>
  get(state, ["network", "config", "file_profiles", "column_fields"]);
