import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  get,
  isEmpty,
  difference,
  map,
  includes,
  endsWith,
  split,
  orderBy,
  compact,
} from "lodash";

import Modal from "components/shared/Modal";
import useItemBatchSelection from "components/shared/hooks/useItemBatchSelection";
import useFolderResource from "../hooks/useFolderResource";
import FolderContentsTable from "../table/FolderContentsTable";
import SelectableFolderRow from "../table/SelectableFolderRow";
import SelectableFileRow from "../table/SelectableFileRow";

function FileSelectModal({ closeModal, currentRootFolder, onSelectFiles }) {
  const { loading, currentFolder, handleNavigate, breadcrumb } =
    useFolderResource({ currentRootFolder });

  const headerRef = useRef(null);
  const [isHoveringHeader, setIsHoveringHeader] = useState(false);
  const [sortDirection, setSortDirection] = useState(1);

  useEffect(() => {
    if (headerRef.current !== null) {
      headerRef.current.onmouseover = () => setIsHoveringHeader(true);
      headerRef.current.onmouseleave = () => setIsHoveringHeader(false);
    }
  }, [headerRef.current]);
  const files = get(currentFolder, "files");
  const ids = map(files, "id");

  const [selectedIds, selectIds] = useState([]);

  const onSelectItem = useItemBatchSelection(ids, selectedIds, selectIds);

  const allSelected = difference(ids, selectedIds).length === 0;

  const selectAll = (e) => {
    if (!e.target.checked) {
      selectIds([]);
    } else {
      selectIds(ids);
    }
  };

  const handleSort = () => {
    setSortDirection(sortDirection === 1 ? -1 : 1);
  };

  const contents = !isEmpty(currentFolder)
    ? [
        ...map(
          orderBy(
            get(currentFolder, "subfolders"),
            ["name"],
            [{ 1: "asc", [-1]: "desc" }[sortDirection]],
          ),
          (folder) => ({
            ...folder,
            type: split(folder.i18n_type, ".")[0],
          }),
        ),
        ...map(
          orderBy(
            get(currentFolder, "files"),
            ["name"],
            [{ 1: "asc", [-1]: "desc" }[sortDirection]],
          ),
          (file) => ({
            ...file,
            type: "file",
          }),
        ),
      ]
    : null;

  const rowProps = (item) => {
    if (endsWith(item.type, "folder")) {
      return {
        disableSelection: true,
        handleNavigate: () =>
          handleNavigate({ id: item.id, namespace: item.namespace }),
      };
    } else {
      return {
        onSelectFile: onSelectItem,
        isSelected: includes(selectedIds, item.id),
        showCheckbox: !isEmpty(selectedIds),
        navigateDisabled: true,
      };
    }
  };

  const selectAllCheckbox = (
    <td className="col-checkbox">
      {(isHoveringHeader || !isEmpty(selectedIds)) && !isEmpty(ids) ? (
        <input type="checkbox" checked={allSelected} onChange={selectAll} />
      ) : null}
    </td>
  );

  const selectedFiles = compact(
    map(files, (file) => (includes(selectedIds, file.id) ? file : null)),
  );

  return (
    <Modal
      onClose={closeModal}
      title={I18n.t("js.files.select")}
      modalActionProps={{
        submitText: "js.files.select",
        submit: (e) => {
          e.preventDefault();
          onSelectFiles(selectedFiles);
          closeModal();
        },
        closeModal: (e) => {
          e.preventDefault();
          closeModal();
        },
      }}
    >
      {breadcrumb}
      <div className="selectable overflow-x-auto">
        <FolderContentsTable
          contents={contents}
          headerRef={headerRef}
          loading={loading}
          extraColumns={[]}
          extraPreColumn={selectAllCheckbox}
          FolderRow={SelectableFolderRow}
          FileRow={SelectableFileRow}
          rowProps={rowProps}
          handleSort={handleSort}
          sortBy={["name", sortDirection]}
          context="modal"
          onlyShowName
        />
      </div>
    </Modal>
  );
}
FileSelectModal.propTypes = {
  closeModal: PropTypes.func,
  onSelectFiles: PropTypes.func,
  currentRootFolder: PropTypes.object,
};

export default FileSelectModal;
