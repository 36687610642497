import {
  isPlainObject,
  some,
  each,
  get,
  map,
  find,
  isEmpty,
  isArray,
  compact,
  split,
  flatten,
  values,
} from "lodash";

export const isPropRequired = (prop) =>
  isPlainObject(get(prop, "required"))
    ? some(values(prop.required))
    : !!get(prop, "required");

// TODO maybe remove?
export const transformPropsForReduxForm = (item, properties) => {
  let values = {};
  each(get(item, "values"), (value, key) => {
    const prop = find(properties, (prop) => prop.name === key);
    values[key] = transformProp(
      get(prop, "type"),
      get(item, ["display_values", key])
        ? get(item, ["display_values", key])
        : value,
    );
  });
  return values;
};

// TODO maybe remove?
export const transformPropsFromReduxForm = (propType, properties, values) => {
  const propsForType = getPropsForType(propType, properties);
  let transformedValues = {};

  each(propsForType, (prop) => {
    const value = get(values, get(prop, "name"));
    if (!isEmpty(value)) {
      transformedValues[get(prop, "name")] = isArray(value)
        ? flatten(map(value, (val) => transformValue(propType, val)))
        : transformValue(propType, value);
    }
  });

  return transformedValues;
};

export const getPropsForType = (type, properties) =>
  compact(
    map(properties, (prop) => {
      if (get(prop, "type") === type) {
        return prop;
      }
    }),
  );

export const transformValue = (type, value) => {
  switch (type) {
    case "image":
      return [
        {
          id: get(value, "id"),
          state: get(value, "state"),
        },
      ];
    case "file":
      return {
        id: get(value, "id"),
        state: get(value, "state"),
      };
    case "tag": {
      let isNew = split(get(value, "value"), "_")[0] === "new";

      const name = isNew ? get(value, "label")[2] : get(value, "label");

      isNew = isNew ? { new: true } : {};

      return { id: get(value, "value"), name, ...isNew };
    }
    case "selection":
      return get(value, "value");
    case "html":
      return value;
    case "relation":
    case "membership":
      return {
        id: get(value, "value"),
        displayValue: get(value, "label"),
      };
  }
};

export const transformProp = (type, value) => {
  switch (type) {
    case "url":
      return get(value, "url");
    case "tag":
      return map(value, (tag) => ({ value: tag.id, label: tag.name }));
    case "selection":
    case "relation":
    case "membership":
      return isArray(value)
        ? map(value, (v) => ({
            value: v.id,
            label: v.displayValue,
          }))
        : {
            value: value.id,
            label: value.displayValue,
          };
    default:
      return value;
  }
};
