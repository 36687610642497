import { useMutation, useQuery } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import { stringify } from "query-string";
import { isNull, omitBy } from "lodash";

type PropertySetParams = {
  name: "file_profile" | "folder_profile" | string;
  contextType: "network" | "group" | string;
  contextId?: string;
  inherit?: boolean;
};

export const propertySetUrl = ({
  name,
  contextType,
  contextId,
  inherit,
}: PropertySetParams) =>
  `/api/v1/property_sets/${name}?${stringify(
    omitBy(
      {
        context_type: contextType,
        context_id: contextId,
        inherit,
      },
      isNull,
    ),
  )}`;

type PropertySetPayload = {
  type: "modified" | "basic";
  properties: { type: "string"; name: "string"; label: "string" }[];
};

export function usePropertySet(params: PropertySetParams, options?) {
  options = options || {};

  // Disable requests for file_ or folder_profile property_sets if they are disabled via network config
  if (
    (options.enabled === undefined && params.name === "file_profile") ||
    params.name == "folder_profile"
  ) {
    const profileConfig = Preload.current_network.config[`${params.name}s`];
    if (!profileConfig?.enabled) options.enabled = false;
  }

  return useQuery<PropertySetPayload>(propertySetUrl(params), {
    staleTime: Infinity,
    retry: false, // disable retry to enable fast rendering of folder listing in case something is misconfigured
    ...options,
  });
}

function persistPropertySet({
  body,
  ...params
}: PropertySetParams & { body: any }) {
  return fetchApi(propertySetUrl(params), {
    method: "PUT",
    body,
  });
}

export function usePersistPropertySet(options?) {
  return useMutation(persistPropertySet, options);
}
