import React from "react";
import { map, some, isFunction, reduce } from "lodash";
import { Modifier, EditorState, RichUtils } from "draft-js";

import Separator from "components/shared/markdownEditor/editorPlugins/buttons/Separator";
import createInlineStyleButton from "components/shared/markdownEditor/editorPlugins/buttons/createInlineStyleButton";
import createPickerButton from "components/shared/markdownEditor/editorPlugins/buttons/createPickerButton";
import CustomConfigurator from "../../../../../CustomConfigurator";

export const colorStyle = (style) => `COLOR-${style.toUpperCase()}`;
export const allColorStyles = () =>
  map(CustomConfigurator.get("markdownEditor.colors"), ({ style }) =>
    colorStyle(style),
  );

export const clearColors = (editorState) => {
  const contentWithoutStyles = reduce(
    allColorStyles(),
    (newContentState, style) =>
      Modifier.removeInlineStyle(
        newContentState,
        editorState.getSelection(),
        style,
      ),
    editorState.getCurrentContent(),
  );

  return EditorState.push(
    editorState,
    contentWithoutStyles,
    "change-inline-style",
  );
};

export const RemoveColorsButton = createInlineStyleButton({
  style: "",
  title: () => I18n.t("js.composer.editor.buttons.no_color"),
  toggleStyle: ({ getEditorState, setEditorState }) => {
    setEditorState(clearColors(getEditorState()));
  },
  isActive: () => false,
  content: <i className="fa fa-ban" />,
});

export const createColorButton = ({ label, style }) =>
  createInlineStyleButton({
    style: colorStyle(style),
    title: () =>
      I18n.t("js.composer.editor.buttons.color", {
        color: isFunction(label) ? label() : label,
      }),
    content: <i className="fa fa-font" style={{ color: style }} />,
    toggleStyle: ({ getEditorState, setEditorState, style }) => {
      setEditorState(
        RichUtils.toggleInlineStyle(clearColors(getEditorState()), style),
      );
    },
  });

export const FontColorsButton = createPickerButton({
  pickerButtons: () =>
    [RemoveColorsButton, Separator].concat(
      map(CustomConfigurator.get("markdownEditor.colors"), createColorButton),
    ),
  title: () => I18n.t("js.composer.editor.buttons.colors"),
  content: <i className="fa fa-font" />,
  isDisabled: ({ getEditorState }) =>
    getEditorState().getSelection().isCollapsed(),
  isActive: ({ getEditorState }) =>
    some(allColorStyles(), (s) =>
      getEditorState().getCurrentInlineStyle().has(s),
    ),
});
export default FontColorsButton;
