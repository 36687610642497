import { draftToMarkdown } from "markdown-draft-js";
import { convertToRaw } from "draft-js";
import { reduce } from "lodash";

import { colorStyle } from "components/shared/markdownEditor/editorPlugins/buttons/FontColorsButton";
import CustomConfigurator from "../../../../CustomConfigurator";

const calculateColorStyles = () =>
  reduce(
    CustomConfigurator.get("markdownEditor.colors"),
    (result, { style }) => {
      result[colorStyle(style)] = {
        open: () => `<span style="color:${style};">`,
        close: () => "</span>",
      };
      return result;
    },
    {},
  );

const markdownByEditorState = (editorState) => {
  const currentContent = editorState.getCurrentContent();
  const rawContent = convertToRaw(currentContent);
  return draftToMarkdown(rawContent, {
    preserveNewlines: true,
    entityItems: {
      mention: {
        open: (entity) => `@[${entity.data.mention.id}:`,
        close: () => "]",
      },
      "#mention": {
        open: (entity) => `#[${entity.data.mention.id}:`,
        close: () => "]",
      },
      IMAGE: {
        open: (entity) => `![${entity.data.alt}](${entity.data.src})`,
        close: () => "",
      },
    },
    styleItems: Object.assign(
      {
        BOLD: {
          open: () => "**",
          close: () => "**",
        },
        ITALIC: {
          open: () => "*",
          close: () => "*",
        },
        UNDERLINE: {
          open: () => "_",
          close: () => "_",
        },
        STRIKETHROUGH: {
          open: () => "~~",
          close: () => "~~",
        },
        CODE: {
          open: () => "`",
          close: () => "`",
        },
        MARK: {
          open: () => "==",
          close: () => "==",
        },
      },
      calculateColorStyles(),
    ),
  });
};

export default markdownByEditorState;
