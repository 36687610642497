import { cloneDeep } from "lodash";
import { ColorRule, DeriveFnEnv, ColorObject } from "./types";
import * as functions from "./functions";

export default function deriveColors(
  colors: Record<string, ColorObject | undefined>,
  colorRules: ColorRule[],
) {
  const result = cloneDeep(colors);

  colorRules.forEach(({ scope, name, derive, abstract, tailwind }) => {
    const variableName = scope ? `${scope}-${name}` : name;
    const env: DeriveFnEnv = { colors: result, scope };

    if (derive && !result[variableName]) {
      const [fnName, ...args] = derive;
      const fn = functions[fnName];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result[variableName] = fn.apply(env, args);
    }

    const singleResult = result[variableName];
    if (singleResult) {
      if (abstract) singleResult.abstract = true;
      if (tailwind) singleResult.tailwind = true;
    }
  });

  return result;
}
