import { createApiResultReducer } from "helpers/api";
import { fetchInvites } from "actions/invites";
import { get, each, findIndex, map } from "lodash";

const apiReducer = createApiResultReducer(fetchInvites, {
  resetOnRequest: true,
  defaultData: [],
});

const reducer = (state = {}, action) => {
  if (
    action.type !== fetchInvites.REQUEST &&
    action.type !== fetchInvites.SUCCESS
  ) {
    switch (action.type) {
      case "/INVITE_ACCEPT/REQUEST": {
        const acceptedInviteIndex = findIndex(
          state.data,
          (i) => i.id === get(action, ["meta", "inviteId"]),
        );
        return {
          ...state,
          data: map(state.data, (data, index) =>
            index === acceptedInviteIndex
              ? {
                  ...state.data[acceptedInviteIndex],
                  status: "accepted",
                }
              : data,
          ),
        };
      }
      case "/INVITE_DECLINE/REQUEST": {
        const declinedInviteIndex = findIndex(
          state.data,
          (i) => i.id === get(action, ["meta", "inviteId"]),
        );
        return {
          ...state,
          data: map(state.data, (data, index) =>
            index === declinedInviteIndex
              ? {
                  ...state.data[declinedInviteIndex],
                  status: "declined",
                }
              : data,
          ),
        };
      }
      case "/INVITES_MORE/SUCCESS": {
        let newData = state.data;
        each(get(action, ["payload", "data"]), (i) => newData.push(i));
        return {
          ...state,
          data: newData,
          loadMoreUrl: get(action, ["payload", "loadMoreUrl"]) || null,
        };
      }
      default:
        return state;
    }
  }
  return apiReducer(state, action);
};

export default reducer;
