import React from "react";
import { omit } from "lodash";

interface DefaultEntryComponentTypes {
  className: string;
  mention?: {
    avatar?: string;
    name: string;
  };
  theme?: {
    mentionSuggestionsEntryAvatar: string;
    mentionSuggestionsEntryText: string;
  };
}

function Avatar({
  mention,
  theme,
}: Omit<DefaultEntryComponentTypes, "className">) {
  if (!mention?.avatar) {
    return null;
  }

  return (
    <img
      src={mention.avatar}
      className={theme?.mentionSuggestionsEntryAvatar}
      role="presentation"
    ></img>
  );
}

function DefaultEntryComponent(props: DefaultEntryComponentTypes) {
  const { mention, theme, ...rest } = props;
  const parentProps = omit(rest, ["isFocused", "searchValue"]);

  return (
    <div {...parentProps}>
      <Avatar mention={mention} theme={theme} />
      {mention?.name ? (
        <span className={theme?.mentionSuggestionsEntryText}>
          {mention.name}
        </span>
      ) : null}
    </div>
  );
}

export default DefaultEntryComponent;
