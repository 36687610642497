import { compose, withHandlers, withProps } from "recompose";
import { RichUtils } from "draft-js";

import ToolbarButton from "components/shared/markdownEditor/editorPlugins/buttons/ToolbarButton";

export default ({ blockType, content, title, toggleStyle, isActive }) => {
  return compose(
    withProps(() => ({
      title,
      children: content,
    })),
    withHandlers({
      onClick: (props) => (e) => {
        e.preventDefault();
        if (toggleStyle) {
          toggleStyle(props);
        } else {
          props.setEditorState(
            RichUtils.toggleBlockType(props.getEditorState(), blockType),
          );
        }
      },
      isActive: (props) => () => {
        if (!props.getEditorState) {
          return false;
        }

        if (isActive) {
          return isActive(props);
        } else {
          const editorState = props.getEditorState();
          const type = editorState
            .getCurrentContent()
            .getBlockForKey(editorState.getSelection().getStartKey())
            .getType();

          return type === blockType;
        }
      },
    }),
  )(ToolbarButton);
};
