import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Field, FormSection } from "redux-form";
import { map } from "lodash";
import classnames from "classnames";

import styles from "./editFields.module.css";
import SimpleInput from "components/appCreator/items/form/SimpleInput";
import SelectionField from "./SelectionField";
import FieldError from "components/appCreator/items/form/FieldError";

const STATES = {
  Deutschland: [
    "Baden-Württemberg",
    "Bayern",
    "Berlin",
    "Brandenburg",
    "Bremen",
    "Hamburg",
    "Hessen",
    "Mecklenburg-Vorpommern",
    "Niedersachsen",
    "Nordrhein-Westfalen",
    "Rheinland-Pfalz",
    "Saarland",
    "Sachsen",
    "Sachsen-Anhalt",
    "Schleswig-Holstein",
    "Thüringen",
  ],
};

function createCountrySelectorFor(country) {
  function Select({
    className,
    id,
    required,
    input,
    meta: { error },
    placeholder,
  }) {
    return (
      <Fragment>
        <SelectionField
          {...input}
          className={className}
          required={required}
          name="state"
          placeholder={placeholder}
          options={map(STATES[country], (state) => ({
            value: state,
            label: state,
          }))}
          id={id}
        />
        {error && <FieldError error={error} />}
      </Fragment>
    );
  }

  Select.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    input: PropTypes.object,
    meta: PropTypes.shape({ error: FieldError.propTypes.error }),
  };

  return Select;
}

const DEFAULT_LABELS = {
  name: "Name",
  street: "Straße",
  number: "Nr.",
  zip: "PLZ",
  city: "Stadt",
  state: "Bundesland",
  country: "Land",
};

function AddressField({
  name,
  disabled,
  required = {},
  with_name,
  labels = DEFAULT_LABELS,
  force_country,
  id,
}) {
  const placeholder = (name) =>
    required[name] ? `${labels[name]} *` : labels[name];

  function fieldFor(fieldName, { className, ...remainingProps } = {}) {
    return (
      <Field
        component={SimpleInput}
        disabled={disabled}
        type="text"
        className={classnames(
          `property-${name}-${fieldName} form-input`,
          className,
        )}
        name={fieldName}
        required={required[fieldName]}
        placeholder={placeholder(fieldName)}
        id={id ? `${id}-${fieldName}` : null}
        {...remainingProps}
      />
    );
  }

  return (
    <FormSection name={name} className={`flex flex-col space-y-2`}>
      {with_name ? (
        <div className={"flex"}>
          {fieldFor("name", { id: id, wrapperClassName: "flex-1" })}
        </div>
      ) : null}
      <div className={`flex space-x-2`}>
        {fieldFor("street", {
          id: with_name ? null : id,
          wrapperClassName: "flex-1",
        })}
        {fieldFor("number", { wrapperClassName: "w-24" })}
      </div>
      <div className={`flex space-x-2`}>
        {fieldFor("zip", { wrapperClassName: "w-36" })}
        {fieldFor("city", { wrapperClassName: "flex-1" })}
      </div>
      <div
        className={classnames(`flex`, {
          [styles.StateRow]: !!STATES[force_country],
        })}
      >
        {fieldFor("state", {
          wrapperClassName: "flex-1",
          component: STATES[force_country]
            ? createCountrySelectorFor(force_country)
            : SimpleInput,
        })}
      </div>
      <div className={`flex`}>
        {fieldFor("country", {
          className: classnames(`property-${name}-name form-input`),
          placeholder: force_country || placeholder("country"),
          disabled: !!force_country || disabled,
          wrapperClassName: "flex-1",
        })}
      </div>
    </FormSection>
  );
}

AddressField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.objectOf(PropTypes.bool),
  force_country: PropTypes.string,
  labels: PropTypes.objectOf(PropTypes.string),
  with_name: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

export default AddressField;
