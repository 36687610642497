import React from "react";
import styles from "./tables.module.css";

import classNames from "classnames";

interface SorterProps {
  attribute: string;
  label: string;
  handleSort?(...args: unknown[]): unknown;
  // sortBy is expected to be a tuple of [attribute, direction]
  // @example Sort by name ascending
  //   ['name', 1]
  sortBy: [string, number];
  disabled?: boolean;
}

function Sorter({
  attribute,
  label,
  sortBy,
  handleSort,
  disabled,
}: SorterProps) {
  // Sort is disabled in file search results
  if (!handleSort || disabled)
    return (
      <span className={classNames("text-sm", styles.SorterDisabled)}>
        {label}
      </span>
    );

  const active = sortBy && sortBy[0] === attribute;
  const reverse = sortBy && sortBy[1] === -1;
  const handleClick = (e) => {
    e.preventDefault();
    handleSort([attribute, active ? (reverse ? 1 : -1) : 1]);
  };

  return (
    <span className={classNames("text-sm", styles.Sorter)}>
      <a href={`#sort-by-${attribute}`} onClick={handleClick}>
        {label}
      </a>
      <i
        className={classNames("fa-solid fa-sort-down", {
          hidden: !active || !reverse,
        })}
      />
      <i
        className={classNames("fa-solid fa-sort-up", {
          hidden: !active || reverse,
        })}
      />
      <i
        className={classNames("text-muted fa-solid fa-sort", {
          hidden: active,
        })}
      />
    </span>
  );
}

export default Sorter;
