import React from "react";
import PropTypes from "prop-types";
import styles from "./showFields.module.css";

function Headline({ property }) {
  return (
    <div className={styles.Headline}>
      {React.createElement(`h${property.size || "3"}`, null, property.label)}
    </div>
  );
}
Headline.propTypes = {
  property: PropTypes.shape({
    label: PropTypes.string,
    size: PropTypes.number,
  }),
};

export default Headline;
