import React from "react";
import classNames from "classnames";

interface ControlsProps {
  wrapperClassName: string;
  children: React.ReactNode;
  label?: React.ReactNode;
  required?: boolean;
  htmlFor?: string;
  name?: string;
  className?: string;

  onLabelClick?(...args: unknown[]): unknown;
}

export function Controls({
  wrapperClassName,
  children,
  label,
  required,
  htmlFor,
  name,
  onLabelClick,
  className,
}: ControlsProps) {
  return (
    <div className={wrapperClassName}>
      {label ? (
        <label
          className="control-label"
          htmlFor={htmlFor}
          onClick={onLabelClick}
          data-property-name={name}
        >
          {label}{" "}
          {required ? <abbr title={I18n.t("js.required")}>*</abbr> : null}
        </label>
      ) : null}
      <div className={classNames("controls", className)}>{children}</div>
    </div>
  );
}

interface ControlGroupProps {
  name?: string;
  label?: React.ReactNode | string;
  required?: boolean;
  children?: React.ReactNode;
  htmlFor?: string;
  className?: string;
  error?: boolean;
  onLabelClick?(...args: unknown[]): unknown;
}

function ControlGroup({
  name,
  label,
  required,
  children,
  htmlFor,
  className,
  error,
  onLabelClick,
}: ControlGroupProps) {
  return (
    <Controls
      wrapperClassName={classNames("control-group input", `property-${name}`, {
        required,
        error,
      })}
      className={className}
      htmlFor={htmlFor || name}
      label={label}
      onLabelClick={onLabelClick}
      required={required}
      name={name}
    >
      {children}
    </Controls>
  );
}

export default ControlGroup;
