import { createApiAction } from "helpers/api";

export const fetchGroup = createApiAction({
  method: "GET",
  endpoint: ({ groupSlug }) => `/api/v1/groups/${groupSlug}`,
  baseType: "GROUP",
});

export const fetchGroupDefaults = createApiAction({
  method: "GET",
  endpoint: ({ groupCategorySlug }) =>
    `/api/v1/groups/new?group_category_slug=${groupCategorySlug}`,
  baseType: "GROUP_DEFAULTS",
});

export const createGroup = createApiAction({
  method: "POST",
  endpoint: () => `/groups`,
  baseType: "GROUP_CREATE",
});

export const updateGroup = createApiAction({
  method: "PUT",
  endpoint: ({ groupSlug }) => `/groups/${groupSlug}`,
  baseType: "GROUP_UPDATE",
});
