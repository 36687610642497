import { markdownToDraft } from "markdown-draft-js";
import { EditorState, convertFromRaw } from "draft-js";
import { reduce } from "lodash";

import customizedTextParser from "components/shared/markdownEditor/parserPlugins/customizedTextParser";
import customizedEmphasisParser from "components/shared/markdownEditor/parserPlugins/customizedEmphasisParser";
import markdownMentionsParser from "components/shared/markdownEditor/parserPlugins/markdownMentionsParser";
import underlineParser from "components/shared/markdownEditor/parserPlugins/underlineParser";
import cssStyleParser from "components/shared/markdownEditor/parserPlugins/cssStyleParser";

import { colorStyle } from "components/shared/markdownEditor/editorPlugins/buttons/FontColorsButton";
import CustomConfigurator from "../../../../CustomConfigurator";

const calculateColorStyles = () =>
  reduce(
    CustomConfigurator.get("markdownEditor.colors"),
    (result, { style }) => {
      result[`color_${style}_open`] = colorStyle(style);
      return result;
    },
    {},
  );

const enableAllStyleRules = (md) => {
  md.inline.ruler.enable(["mark"]);
  md.block.ruler.disable(["hr", "table"]);
};

const editorStateByMarkdown = (content) => {
  const newRawState = markdownToDraft(content, {
    preserveNewlines: true,
    remarkablePlugins: [
      enableAllStyleRules,
      customizedTextParser,
      customizedEmphasisParser,
      markdownMentionsParser,
      underlineParser,
      cssStyleParser,
    ],
    remarkableOptions: {
      html: false,
    },
    blockEntities: {
      mention_open: (item) => ({
        type: item.marker === "#" ? "#mention" : "mention",
        mutability: "IMMUTABLE",
        data: {
          mention: {
            id: item.id,
            name: item.name,
          },
        },
      }),
      image: (item) => ({
        type: "IMAGE",
        mutability: "IMMUTABLE",
        data: {
          alt: item.alt,
          src: item.src,
        },
      }),
    },
    blockStyles: Object.assign(
      {
        mark_open: "MARK",
        del_open: "STRIKETHROUGH",
        underline_open: "UNDERLINE",
      },
      calculateColorStyles(),
    ),
  });

  return EditorState.createWithContent(convertFromRaw(newRawState));
};

export default editorStateByMarkdown;
