import React from "react";
import PropTypes from "prop-types";

import FieldError from "./FieldError";
import styles from "../../properties/edit/editFields.module.css";
import classNames from "classnames";

function SimpleInput({
  input,
  meta: { error },
  wrapperClassName,
  prefix,
  postfix,
  ...otherProps
}) {
  let inputElement = <input {...input} {...otherProps} />;

  if (prefix || postfix)
    inputElement = (
      <div className="input-group">
        {prefix && <span className="input-group-text">{prefix}</span>}
        {inputElement}
        {postfix && <span className="input-group-text">{postfix}</span>}
      </div>
    );

  return (
    <div className={classNames(styles.SimpleInput, wrapperClassName)}>
      {inputElement}
      {error && <FieldError error={error} />}
    </div>
  );
}

SimpleInput.propTypes = {
  meta: PropTypes.shape({ error: FieldError.propTypes.error }),
  input: PropTypes.object,
};

export default SimpleInput;
