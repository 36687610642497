import PropTypes from "prop-types";
import React from "react";
import { Field } from "redux-form";

import styles from "./editFields.module.css";
import SimpleInput from "components/appCreator/items/form/SimpleInput";

function EmailField({ name, required, id, disabled }) {
  return (
    <Field
      component={SimpleInput}
      type="email"
      className={`${styles.Email} property-${name} form-input`}
      name={name}
      required={required}
      id={id}
      disabled={disabled}
    />
  );
}
EmailField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

export default EmailField;
