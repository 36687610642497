import { createApiAction, transformLinkHeader } from "helpers/api";

export const fetchParticipations = createApiAction({
  method: "GET",
  endpoint: ({ status }) => `/api/v1/participations?status=${status}&limit=20`,
  baseType: `/PARTICIPATIONS`,
  transformPayload: transformLinkHeader,
});

export const loadMoreParticipations = createApiAction({
  method: "GET",
  endpoint: ({ url }) => url,
  baseType: `/PARTICIPATIONS_MORE`,
  transformPayload: transformLinkHeader,
});

export const changeParticipantStatus = createApiAction({
  method: "PUT",
  endpoint: ({ appointmentId, id }) =>
    `/appointments/${appointmentId}/participants/${id}`,
  baseType: `/APPOINTMENT/PARTICIPANTS/CHANGE_STATUS`,
});

export const addParticipations = createApiAction({
  method: "POST",
  endpoint: ({ appointmentId }) =>
    `/appointments/${appointmentId}/participants`,
  baseType: `/APPOINTMENT/PARTICIPANTS/ADD`,
});

export const removeParticipation = createApiAction({
  method: "DELETE",
  endpoint: ({ appointmentId, id }) =>
    `/appointments/${appointmentId}/participants/${id}`,
  baseType: `/APPOINTMENT/PARTICIPANTS/REMOVE`,
});
