import { createApiAction } from "helpers/api";
import { createRequestTypes, requestAction, action } from "./helpers";

export const FILE = {
  PUBLISH: "/FILE/PUBLISH",
  REMOVE: "/FILE/REMOVE",
  UPLOAD: createRequestTypes("/FILE/UPLOAD"),
};

export const FILES = {
  SET_UPLOAD_IDS: "/FILES/SET_UPLOAD_IDS",
  ADD_UPLOAD_ID: "FILES/ADD_UPLOAD_ID",
  UPLOAD: createRequestTypes("/FILES/UPLOAD"),
  PUBLISH: "/FILES/PUBLISH",
  DESTROY: "/FILES/DESTROY",
  MOVE: "FILES/MOVE",
  DELETE: "/FILES/DELETE",
};

export const FOLDERS = {
  MOVE: "FOLDERS/MOVE",
  DELETE: "FOLDERS/DELETE",
  SELECT: "FOLDERS/SELECT",
};

export const FOLDER = {
  SELECT: "FOLDER/SELECT",
};

export const publishFiles = createApiAction({
  method: "POST",
  endpoint: ({ storageDirectory = "files" }) =>
    `/api/storage/${storageDirectory}/publish`,
  baseType: FILES.PUBLISH,
});

export const uploadFile = (payload) => {
  return requestAction(FILES.UPLOAD, payload);
};

export const destroyFile = createApiAction({
  method: "DELETE",
  endpoint: ({ storageDirectory, id }) =>
    `/api/storage/${storageDirectory}/${id}`,
  baseType: FILES.DESTROY,
});

export const assetsUploadFile = createApiAction({
  method: "POST",
  endpoint: () => "/api/storage/assets",
  baseType: "ASSETS/UPLOAD",
});

export const moveFiles = createApiAction({
  method: "POST",
  endpoint: () => "/api/storage/files/move",
  baseType: FILES.MOVE,
});

export const moveFolders = createApiAction({
  method: "POST",
  endpoint: () => "/api/storage/folders/move",
  baseType: FOLDERS.MOVE,
});
export const removeFiles = createApiAction({
  method: "DELETE",
  endpoint: () => "/api/storage/files",
  baseType: FILES.DELETE,
});

export const removeFolders = createApiAction({
  method: "DELETE",
  endpoint: () => "/api/storage/folders",
  baseType: FOLDERS.DELETE,
});

export const fetchFolder = createApiAction({
  method: "GET",
  endpoint: ({ id, namespace = "folders" }) =>
    `/api/storage/${namespace}/${id}?limit=1000`,
  baseType: "FOLDER",
});

export const selectFolder = ({ id, namespace }) =>
  action(FOLDER.SELECT, { meta: { id, namespace } });
