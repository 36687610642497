import { useQuery } from "react-query";
import { isEmpty } from "lodash";

export const appsApiBaseUrl = () => "/api/apps";
export const appUrl = ({ externalAppId, appId }) => {
  if (isEmpty(externalAppId) && isEmpty(appId))
    throw new Error("appId or externalAppId required to build appUrl");

  return [
    appsApiBaseUrl(),
    "apps",
    externalAppId ? `external/${externalAppId}` : appId,
  ].join("/");
};

export const itemUrl = ({ appId, externalAppId, itemId, externalItemId }) => {
  if (isEmpty(itemId) && isEmpty(externalItemId))
    throw new Error("itemId or externalItemId required to build itemUrl");

  return [
    appUrl({ appId, externalAppId }),
    "items",
    externalItemId ? `external/${externalItemId}` : itemId,
  ].join("/");
};

type Property = {
  type: string;
  name: string;
};

type AppPayload = {
  collection_layout: {
    layout: "table" | "list" | "wall";
    options: {
      table: { stage: boolean };
      list: {
        stage: boolean;
        teaser: {
          body_template: string | undefined;
        };
      };
      wall: { stage: boolean };
    };
  };
  id: string;
  name: string;
  title_property: string;
  properties: Property[];
};

export const useLoadApp = ({ appId, externalAppId }, options?) =>
  useQuery<AppPayload>(appUrl({ appId, externalAppId }), options);

type ItemPayload = {
  id: string;
  values: {
    [key: string]: undefined | string | { url: string } | { url: string }[];
  };
  display_values: object;
};

export const useLoadItem = ({ appId, externalAppId, itemId, externalItemId }) =>
  useQuery<ItemPayload>(
    itemUrl({ appId, externalAppId, itemId, externalItemId }),
  );
