import PropTypes from "prop-types";
import React from "react";
import { Field } from "redux-form";

import FileUploadField from "../../../shared/fields/FileUploadField";

function ImageField({ name, required, multiple, disabled }) {
  return (
    <Field
      name={name}
      required={required}
      component={FileUploadField}
      props={{
        isImage: true,
        storageDirectory: "images",
        inputTitle: I18n.t("js.files.uploader.select_image"),
        multiple,
        disabled,
      }}
    />
  );
}
ImageField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ImageField;
