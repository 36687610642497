import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AppState = {
  title: string | null;
};

const initialState: AppState = {
  title: null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
    },
  },
});

export const { setTitle } = appSlice.actions;
export default appSlice.reducer;
