import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import styles from "./showFields.module.css";

function DateView({ value, displayValue }) {
  return (
    <time dateTime={value} className={styles.Date}>
      {get(displayValue, "displayValue") || displayValue || value}
    </time>
  );
}
DateView.propTypes = {
  value: PropTypes.string,
  displayValue: PropTypes.string,
};

export default DateView;
