import { combineReducers } from "redux";
import { createApiResultReducer } from "helpers/api";
import {
  fetchApp,
  fetchAppLink,
  fetchItem,
  exportItemsGetLast,
} from "actions/appCreator";
import { fetchApps } from "actions/administration/appCreator";
import items from "./items";

export default combineReducers({
  item: createApiResultReducer(fetchItem, {
    resetOnRequest: true,
  }),
  items,
  app: createApiResultReducer(fetchApp, {
    resetOnRequest: true,
  }),
  appLink: createApiResultReducer(fetchAppLink),
  apps: createApiResultReducer(fetchApps, {
    defaultData: [],
    resetOnRequest: true,
  }),
  exports: createApiResultReducer(exportItemsGetLast),
});
