import {
  RESET_ERRORS,
  fetchWidgetbar,
  updateWidgetbar,
  fetchCalendars,
} from "actions/administration/widgetbar";

const initialState = {
  updateWidgetbar: false,
  fetchWidgetbar: false,
  fetchCalendars: false,
  notFound: false,
};

const errorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case fetchWidgetbar.FAILURE: {
      return {
        ...state.errors,
        fetchWidgetbar: true,
        notFound: action.payload.status === 404,
      };
    }
    case fetchCalendars.FAILURE: {
      return {
        ...state.errors,
        fetchCalendars: true,
      };
    }
    case updateWidgetbar.FAILURE: {
      return {
        ...state,
        updateWidgetbar: true,
      };
    }
    case RESET_ERRORS: {
      return {};
    }
    default: {
      return state;
    }
  }
};

export default errorsReducer;
