import React from "react";
import PropTypes from "prop-types";
import { map, castArray, compact, isEmpty } from "lodash";
import classnames from "classnames";

function GenericValueList({ values, component, listItemStyle, listStyle }) {
  return (
    <ul
      className={classnames({
        [`${listStyle}`]: !isEmpty(listStyle),
      })}
    >
      {map(compact(castArray(values)), (val, i) => (
        <li key={val.id || i} className={listItemStyle || ""}>
          {component(val)}
        </li>
      ))}
    </ul>
  );
}

GenericValueList.propTypes = {
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  component: PropTypes.func.isRequired,
  listItemStyle: PropTypes.string,
  listStyle: PropTypes.string,
};

export default GenericValueList;
