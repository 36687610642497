import { map, partition, fromPairs, isEmpty } from "lodash";

import { publishFiles, destroyFile } from "actions/files";

// Publishes all files or images with state 'volatile' which exist in values.
// Used before saving items to apps api,before sending message with attachments,
// etc.
//
// @example
//   const result = await publishAllVolatile({
//     dispatch,
//     values: { profile_image: [{ id: 123, state: "volatile", ... }] },
//     storageDirectory: 'images',
//   });
//
//   console.log(result)
//   //=> { profile_image: [{ id: 123, state: "published", token: 456, ... }] }
const publishAllVolatile = async ({ dispatch, values, storageDirectory }) => {
  const pairs = await Promise.all(
    map(values, async (files, fieldName) => {
      const [volatile, nonVolatile] = partition(files, { state: "volatile" });
      const [remove, others] = partition(nonVolatile, { state: "removed" });

      if (isEmpty(volatile) && isEmpty(remove)) return [fieldName, files];

      const { payload: published } = await dispatch(
        publishFiles({
          body: { [storageDirectory]: volatile },
          storageDirectory,
        }),
      );

      await Promise.all(
        map(
          remove,
          async (file) =>
            await dispatch(
              destroyFile({
                id: file.id,
                storageDirectory,
              }),
            ),
        ),
      );

      return [fieldName, [...others, ...published]];
    }),
  );
  return fromPairs(pairs);
};

export default publishAllVolatile;
