import React, { useEffect, useState } from "react";
import createToolbarPlugin from "@draft-js-plugins/static-toolbar";
import { map, isString } from "lodash";

import {
  BoldButton,
  ItalicButton,
  UnderlineButton,
  StrikeButton,
  MarkButton,
  CodeButton,
} from "components/shared/markdownEditor/editorPlugins/buttons/inlineStyleButtons";
import {
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton,
} from "components/shared/markdownEditor/editorPlugins/buttons/blockStyleButtons";
import HeadlinesButton, {
  headlineButtons,
} from "components/shared/markdownEditor/editorPlugins/buttons/HeadlinesButton";
import FontColorsButton from "components/shared/markdownEditor/editorPlugins/buttons/FontColorsButton";
import LinkButton from "components/shared/markdownEditor/editorPlugins/buttons/LinkButton";
import Separator from "components/shared/markdownEditor/editorPlugins/buttons/Separator";
import CustomConfigurator from "../../../../CustomConfigurator";

const AvailableButtons = Object.assign(
  {
    Separator,
    BoldButton,
    ItalicButton,
    UnderlineButton,
    StrikeButton,
    MarkButton,
    CodeButton,
    UnorderedListButton,
    OrderedListButton,
    BlockquoteButton,
    CodeBlockButton,
    LinkButton,
    FontColorsButton,
    HeadlinesButton,
  },
  headlineButtons,
);

export default function createCustomToolbarPlugin() {
  const Plugin = createToolbarPlugin();
  function Toolbar() {
    const [key, setKey] = useState(Math.random());

    useEffect(() => {
      const composer = document.querySelector(".activity-composer");
      composer?.addEventListener("click", click);
      return () => composer?.removeEventListener("click", click);

      function click({ target }) {
        if (target === composer) {
          setKey(Math.random());
        }
      }
    }, []);

    return (
      <Plugin.Toolbar key={key}>
        {(externalProps) => (
          <div className="draftJsToolbar__toolbar__dNtBH">
            {map(CustomConfigurator.get("markdownEditor.buttons"), (B, i) => {
              const ButtonElem = AvailableButtons[B];
              return isString(B) ? (
                <ButtonElem key={i} {...externalProps} />
              ) : (
                <B key={i} {...externalProps} />
              );
            })}
          </div>
        )}
      </Plugin.Toolbar>
    );
  }
  return [Plugin, Toolbar];
}
