import React from "react";
import PropTypes from "prop-types";
import { unescape } from "lodash";

import styles from "./showFields.module.css";

function Html({ value }) {
  return (
    <div
      className={styles.Html}
      dangerouslySetInnerHTML={{
        __html: unescape(value),
      }}
    />
  );
}

Html.propTypes = {
  value: PropTypes.string,
};

export default Html;
