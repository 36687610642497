import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import FileIcon from "../../shared/FileIcon";
import FileInfo, { FileName } from "./FileInfo";
import { FileRowPropTypes } from "./FileRow";

const hiddenStyle = { opacity: 0, visibility: "hidden" };

const SelectableFileRow = React.memo(function SelectableFileRow({
  id,
  name,
  extension,
  handleNavigate,
  version,
  size,
  author,
  updated_at,
  extraColumns,
  profile = {},
  isSelected,
  onSelectFile,
  className,
  content_type,
  disableSelection,
  showCheckbox,
  onlyShowName,
  navigateDisabled,
}) {
  const [hover, setHover] = useState(false);

  const active = hover || isSelected;
  const checkboxAvailable = (active || showCheckbox) && !disableSelection;

  return (
    <tr
      className={classNames("file-row", className, {
        ["hover:bg-primary/25 cursor-pointer"]: !disableSelection || active,
      })}
      onMouseOver={!disableSelection ? () => setHover(true) : null}
      onMouseLeave={!disableSelection ? () => setHover(false) : null}
      onClick={
        !disableSelection
          ? () => {
              onSelectFile(id, true);
            }
          : null
      }
    >
      <td className="col-checkbox p-2 text-center">
        <input
          type="checkbox"
          value={id}
          checked={isSelected}
          disabled={!checkboxAvailable}
          style={checkboxAvailable ? null : hiddenStyle}
          onChange={
            !disableSelection
              ? () => {
                  onSelectFile(id);
                }
              : null
          }
        />
      </td>
      <td className="col-icon file-icon text-center" width="1%">
        <FileIcon extension={extension} className={"text-xl"} />
      </td>
      {onlyShowName ? (
        <FileName
          id={id}
          handleNavigate={handleNavigate}
          name={name}
          version={version}
          navigateDisabled={navigateDisabled}
        />
      ) : (
        <FileInfo
          id={id}
          name={name}
          handleNavigate={handleNavigate}
          version={version}
          size={size}
          author={author}
          updatedAt={updated_at}
          extraColumns={extraColumns}
          profile={profile}
          contentType={content_type}
        />
      )}
    </tr>
  );
});

SelectableFileRow.propTypes = {
  ...FileRowPropTypes,
  isSelected: PropTypes.bool,
  onSelectFile: PropTypes.func,
  disableSelection: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  onlyShowName: PropTypes.bool,
};

export default SelectableFileRow;
