import { stringify } from "query-string";
import { createApiAction } from "helpers/api";
import { isEmpty } from "lodash";

export const fetchReservableMeetingRooms = createApiAction({
  method: "GET",
  endpoint: ({ dateRange, roomId }) => {
    const query = {
      filter: "reservable",
      ...dateRange,
      room_id: !isEmpty(roomId) ? roomId : null,
    };

    return `/meeting_rooms?${stringify(query)}`;
  },
  baseType: `MEETING_ROOMS/RESERVABLE`,
});
