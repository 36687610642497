import { fetchWidgetbars } from "actions/administration/widgetbar";

const initialState = {
  widgetbars: [],
};

const indexReducer = (state = initialState, action) => {
  switch (action.type) {
    case fetchWidgetbars.FAILURE: {
      return {
        ...state,
        widgetbars: [],
      };
    }
    case fetchWidgetbars.SUCCESS: {
      return {
        ...state,
        widgetbars: action.payload.widgetbars,
      };
    }
    default: {
      return state;
    }
  }
};

export default indexReducer;
