import { get } from "lodash";
import { createSelector } from "reselect";
import { currentRouteParamsSelector } from "./navigation";

import { transformPropsForReduxForm } from "components/appCreator/properties/helpers";

export const itemsInfoSelector = (state) => get(state, "appCreator.items");

export const itemsSelector = (state) =>
  get(state, ["appCreator", "items", "data"]);

export const selectItemResult = (state) => get(state, ["appCreator", "item"]);
export const itemSelector = (state) => get(state, "appCreator.item.data");
export const currentItemIdSelector = (state) => get(itemSelector(state), "id");

export const loadMoreUrlsSelector = (state) =>
  get(state, ["appCreator", "items", "loadMoreUrls"]);

export const totalArchivedItemsSelector = (state) =>
  get(state, ["appCreator", "items", "totalArchivedItems"]);

export const itemPaginationSelector = (state) =>
  get(itemsInfoSelector(state), "pagination");

export const appsInfoSelector = (state) => get(state, "appCreator.apps");

export const appsSelector = (state) => get(appsInfoSelector(state), "data");

export const selectAppResult = (state) => get(state, ["appCreator", "app"]);
export const appSelector = (state) => get(state, "appCreator.app.data");

export const appLinkSelector = (state) => get(state, "appCreator.appLink");

export const propertiesSelector = (state) =>
  get(appSelector(state), "properties");

export const itemsExportsSelector = (state) =>
  get(state, "appCreator.exports.data");

export const itemsLoadingSelector = (state) =>
  get(state, "appCreator.items.loading");

export const itemsErrorSelector = (state) =>
  get(state, "appCreator.items.error");

export const itemLoadingSelector = (state) =>
  get(state, "appCreator.item.loading");

// item form
export const initialFormValuesSelector = createSelector(
  [itemSelector, appSelector],
  (item, app) => transformPropsForReduxForm(item, get(app, "properties")),
);

export const appSlugSelector = (state) =>
  get(currentRouteParamsSelector(state), "slug");

export const relationOptionsSelector = (state) =>
  get(state, ["appCreator", "relationOptions"]);
