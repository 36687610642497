import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

import preventDefault from "../../../helpers/components/preventDefault";
import FileSelectModal from "./FileSelectModal";

function FilesEditorView({ currentRootFolder, onSelectFiles }) {
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  return (
    <Fragment>
      {" "}
      <span>{I18n.t("js.or")} </span>
      <a
        className="btn btn-light btn-sm file-selector-select"
        href="#"
        onClick={preventDefault(() => setShowModal(true))}
      >
        <i className="fa-regular fa-file pr-1" />
        {I18n.t("js.files.file_selector.select")}
      </a>
      {showModal ? (
        <FileSelectModal
          closeModal={closeModal}
          currentRootFolder={currentRootFolder}
          onSelectFiles={onSelectFiles}
        />
      ) : null}
    </Fragment>
  );
}

FilesEditorView.propTypes = {
  currentRootFolder: PropTypes.object,
  onSelectFiles: PropTypes.func,
};

export default FilesEditorView;
