import { useEffect } from "react";

const useWindowEvent = (eventName, eventHandler) => {
  useEffect(() => {
    window.addEventListener(eventName, eventHandler);
    return () => window.removeEventListener(eventName, eventHandler);
  }, [eventName, eventHandler]);
};

export default useWindowEvent;
