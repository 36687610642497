import React from "react";
import { map, padStart, includes, reduce, values } from "lodash";

import Separator from "components/shared/markdownEditor/editorPlugins/buttons/Separator";
import createBlockStyleButton from "components/shared/markdownEditor/editorPlugins/buttons/createBlockStyleButton";
import createPickerButton from "components/shared/markdownEditor/editorPlugins/buttons/createPickerButton";

const HEADLINES = [
  { type: "header-one", level: 1 },
  { type: "header-two", level: 2 },
  { type: "header-three", level: 3 },
  { type: "header-four", level: 4 },
  { type: "header-five", level: 5 },
];

export const headlineButtons = reduce(
  HEADLINES,
  (result, { type, level }) => {
    result[`H${level}Button`] = createBlockStyleButton({
      blockType: type,
      title: () =>
        I18n.t("js.composer.editor.buttons.headline", {
          level: level,
          markdown_shortcut: padStart("", level, "#"),
        }),
      content: <span>H{level}</span>,
    });

    return result;
  },
  {},
);

export const RemoveHeadlineButton = createBlockStyleButton({
  blockType: "unstyled",
  title: () => I18n.t("js.composer.editor.buttons.no_headline"),
  isActive: () => false,
  content: <i className="fa fa-ban" />,
});

export const HeadlinesButton = createPickerButton({
  pickerButtons: [RemoveHeadlineButton, Separator].concat(
    values(headlineButtons),
  ),
  title: () => I18n.t("js.composer.editor.buttons.headlines"),
  content: <i className="fa fa-header" />,
  isActive: ({ getEditorState }) => {
    const editorState = getEditorState();
    const type = editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getStartKey())
      .getType();

    return includes(map(HEADLINES, "type"), type);
  },
});
export default HeadlinesButton;
