import { Remarkable } from "remarkable";
import { linkify } from "remarkable/linkify";
import { isString, memoize } from "lodash";
import extractUrl from "./extractUrl";

export const setApplication = () => ({
  ...window.Application,
  getNewMarkdownRenderer: (options = {}) =>
    new Remarkable(options).use(linkify),
  getMarkdownRenderer: memoize(() =>
    window.Application.getNewMarkdownRenderer(),
  ),
  markdown: (text) => {
    if (isString(text)) {
      return (
        window.Application.getMarkdownRenderer()
          .render(text)
          // convert <h1> to <h2> etc.
          .replace(
            /<(\/?)h([1-5])>/g,
            (match, slash, number) => `<${slash}h${number * 1 + 1}>`,
          )
      );
    } else {
      return "";
    }
  },
  extractUrl,
});
