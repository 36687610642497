import React from "react";
import PropTypes from "prop-types";
import { get, isEmpty } from "lodash";

import Text from "../properties/show/Text";
import Email from "../properties/show/Email";
import Image from "../properties/show/Image";
import Url from "../properties/show/Url";
import File from "../properties/show/File";
import Selection from "../properties/show/Selection";
import Relation from "../properties/show/Relation";
import MembershipRelation from "../properties/show/MembershipRelation";
import DateView from "../properties/show/DateView";
import Statistics from "../properties/show/Statistics";
import Address from "../properties/show/Address";
import Tag from "../properties/show/Tag";
import Html from "../properties/show/Html";
import Headline from "../properties/show/Headline";

const PROPERTIES = {
  address: Address,
  email: Email,
  image: Image,
  membership: MembershipRelation,
  selection: Selection,
  text: Text,
  url: Url,
  number: Text,
  tag: Tag,
  file: File,
  date: DateView,
  statistics: Statistics,
  html: Html,
  relation: Relation,
  headline: Headline,
};

function PropertyShow({ property, options, item }) {
  const type = get(property, "type");
  const name = get(property, "name");
  const value = get(item, ["values", name]);
  const displayValue =
    get(item, ["values", "$_displayValues_", name]) ||
    get(item, ["display_values", name]);

  const Component = PROPERTIES[type];
  if (Component) {
    if (isEmpty(value) && isEmpty(displayValue)) return null;

    return (
      <Component
        property={property}
        options={options}
        value={value}
        displayValue={displayValue}
        item={value} // deprecated
      />
    );
  } else {
    return <div>Unknown property type</div>;
  }
}
PropertyShow.propTypes = {
  property: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  item: PropTypes.shape({
    values: PropTypes.shape({
      $_displayValues_: PropTypes.object,
      // this includes all plain values under the name as key
    }).isRequired,
    display_values: PropTypes.object,
  }),
  options: PropTypes.object,
};

export default PropertyShow;
