import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

function Url({ value }) {
  return !isEmpty(value) ? (
    <a href={`${value.url}`} target="_blank" rel="noopener noreferrer">
      {value.text || value.url}
    </a>
  ) : null;
}
Url.propTypes = {
  value: PropTypes.shape({
    url: PropTypes.string.isRequired,
    text: PropTypes.string,
  }),
};

export default Url;
