import {
  archiveItem,
  fetchItems,
  loadMoreItemsByColumn,
  moveItem,
  unarchiveItem,
  updateItem,
} from "../../actions/appCreator";
import {
  concat,
  filter,
  findIndex,
  findKey,
  flatMap,
  get,
  isEmpty,
  omit,
  pull,
  toNumber,
} from "lodash";

const defaultState = {
  data: [],
  loadMoreUrls: {},
  loading: false,
  error: null,
  meta: {},
};

const itemsRequestReducer = (state = defaultState, action) => {
  let loadMoreUrls = get(action.payload, "loadMoreUrls");

  switch (action.type) {
    case fetchItems.REQUEST:
    case loadMoreItemsByColumn.REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case loadMoreItemsByColumn.SUCCESS:
      return {
        ...state,
        data: concat(state.data, get(action.payload, "data")),
        loadMoreUrls: !isEmpty(loadMoreUrls)
          ? { ...state.loadMoreUrls, ...loadMoreUrls }
          : omit(
              state.loadMoreUrls,
              findKey(
                state.loadMoreUrls,
                (url) => url === get(action, "meta.url"),
              ),
            ),
        loading: false,
        meta: action.meta,
      };
    case fetchItems.SUCCESS:
      return {
        ...state,
        data: get(action, "payload.data") || get(action, "payload"),
        pagination: get(action, "payload.pagination") || null,
        loadMoreUrls: {
          ...state.loadMoreUrls,
          ...loadMoreUrls,
        },
        totalArchivedItems: get(action.payload, "totalArchivedItems"),
        loading: false,
        meta: action.meta,
      };
    case fetchItems.FAILURE: {
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload,
      };
    }
    case archiveItem.REQUEST: {
      const column = get(action, "meta.column");
      const totalArchivedItems = column
        ? {
            ...state.totalArchivedItems,
            [column]: state.totalArchivedItems
              ? toNumber(state.totalArchivedItems[column]) + 1
              : 0 + 1,
          }
        : toNumber(state.totalArchivedItems) + 1;

      return {
        ...state,
        data: filter(state.data, (i) => i.id !== action.meta.itemId),
        totalArchivedItems,
      };
    }
    case unarchiveItem.REQUEST: {
      return {
        ...state,
        data: filter(state.data, (i) => i.id !== action.meta.itemId),
      };
    }
    default:
      return state;
  }
};

export default (state, action) => {
  if (
    action.type === updateItem.REQUEST &&
    action.meta.optimistic &&
    action.meta.insertAfterCardId !== action.meta.itemId
  ) {
    const newItem =
      state.data[findIndex(state.data, (i) => i.id === action.meta.itemId)];
    const changedItem = {
      ...newItem,
      values: { ...newItem.values, ...get(action, "meta.body.values") },
    };
    const data = !isEmpty(action.meta.insertAfterCardId)
      ? flatMap(pull(state.data, newItem), (item) => {
          if (item.id === action.meta.insertAfterCardId) {
            return [item, changedItem];
          }
          return item;
        })
      : flatMap([changedItem, pull(state.data, newItem)]);
    return { ...state, data };
  }

  switch (action.type) {
    case moveItem.REQUEST: {
      const newItem = get(state, "data")[
        findIndex(
          get(state, "data"),
          (i) => i.id === get(action, "meta.itemId"),
        )
      ];
      const changedItem = {
        ...newItem,
        values: { ...newItem.values, ...get(action, "meta.body.values") },
      };
      const data = !isEmpty(action.meta.insertAfterCardId)
        ? flatMap(pull(state.data, newItem), (item) => {
            if (item.id === action.meta.insertAfterCardId) {
              return [item, changedItem];
            }
            return item;
          })
        : flatMap([changedItem, pull(state.data, newItem)]);
      return { ...state, data };
    }
    case moveItem.SUCCESS: {
      const newItem = get(state, "data")[
        findIndex(
          get(state, "data"),
          (i) => i.id === get(action, "meta.itemId"),
        )
      ];

      const data = !isEmpty(action.meta.insertAfterCardId)
        ? flatMap(pull(state.data, newItem), (item) => {
            if (item.id === action.meta.insertAfterCardId) {
              return [item, get(action, "payload")];
            }
            return item;
          })
        : flatMap([get(action, "payload"), pull(state.data, newItem)]);
      return { ...state, data };
    }
  }
  return itemsRequestReducer(state, action);
};
