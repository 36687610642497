import { reduce } from "lodash";

// Turns given collection into lookup object using given key value
export default function indexBy<T>(
  collection: T[] | undefined,
  key: string,
): { [key: string]: T | undefined } {
  return reduce(
    collection,
    (obj, elem) => {
      obj[elem[key]] = elem;
      return obj;
    },
    {},
  );
}
