import { combineReducers } from "redux";

import { createApiResultReducer } from "helpers/api";
import { fetchReservableMeetingRooms } from "actions/meetingRooms";

const reducer = combineReducers({
  reservableMeetingRoomsRequest: createApiResultReducer(
    fetchReservableMeetingRooms,
    {
      resetOnRequest: true,
    },
  ),
});

export default reducer;
