import { createApiAction } from "helpers/api";
import { createFormAction } from "redux-form-saga";

export const NAMESPACE = "administration/appCreator";

export const fetchApps = createApiAction({
  method: "GET",
  endpoint: ({ url }) => url,
  baseType: `${NAMESPACE}/APPS`,
});

export const saveApp = createFormAction(`${NAMESPACE}/APP/SAVE`);

export const createApp = createApiAction({
  method: "POST",
  endpoint: () => "/api/apps/apps",
  baseType: `${NAMESPACE}/APP/CREATE`,
});

export const postAppId = createApiAction({
  method: "POST",
  endpoint: ({ groupSlug }) =>
    `${groupSlug ? `/groups/${groupSlug}` : ""}/administration/apps`,
  baseType: `${NAMESPACE}/APPS/POST_APP_ID`,
});

export const updateApp = createApiAction({
  method: "PUT",
  endpoint: ({ appId }) => `/api/apps/apps/${appId}`,
  baseType: `${NAMESPACE}/APP/UPDATE`,
});

export const deleteApp = createApiAction({
  method: "DELETE",
  endpoint: ({ appSlug }) => `apps/${appSlug}`,
  baseType: `${NAMESPACE}/APP/DELETE`,
});
