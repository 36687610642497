import { createApiAction, transformLinkHeader } from "helpers/api";

export const fetchInvites = createApiAction({
  method: "GET",
  endpoint: ({ filter, status }) =>
    `/api/v1/invites?filter=${filter}&status=${status}&limit=50`,
  baseType: `/INVITES`,
  transformPayload: transformLinkHeader,
});

export const loadMoreInvites = createApiAction({
  method: "GET",
  endpoint: ({ url }) => url,
  baseType: `/INVITES_MORE`,
  transformPayload: transformLinkHeader,
});

export const declineInvite = createApiAction({
  method: "POST",
  endpoint: ({ inviteId }) => `/api/v1/invites/${inviteId}/reject`,
  baseType: `/INVITE_DECLINE`,
});

export const acceptInvite = createApiAction({
  method: "POST",
  endpoint: ({ inviteId }) => `/api/v1/invites/${inviteId}/accept`,
  baseType: `/INVITE_ACCEPT`,
});
