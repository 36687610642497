import React from "react";
import createUndoPlugin from "@draft-js-plugins/undo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function customUndoPlugin() {
  return createUndoPlugin({
    undoContent: <FontAwesomeIcon icon={solid("arrow-rotate-left")} />,
    redoContent: <FontAwesomeIcon icon={solid("arrow-rotate-right")} />,
    theme: {
      undo: "btn btn-sm btn-light",
      redo: "btn btn-sm btn-light",
    },
  });
}
