import { RSAA } from "redux-api-middleware";
import { isPlainObject, isEmpty, get, flow } from "lodash";
import { SubmissionError } from "redux-form";
import bustCache from "./api/bustCache";

export { default as parseHeader } from "./api/parseHeader";
export { default as transformLinkHeader } from "./api/transformLinkHeader";
import indexBy from "./indexBy";

const maybeSerializeBodyAsJson = ({ body }) =>
  isPlainObject(body) ? JSON.stringify(body) : body;

export const csrfTokenHeader = () => {
  const meta = document.querySelector('meta[name="csrf-token"]');
  return { "X-CSRF-Token": meta ? meta.getAttribute("content") : null };
};

export const headers = (method, meta = {}) => {
  let h = { Accept: "application/json", "Cache-Control": "no-store" };

  if (method === "POST" || method === "PUT" || method === "DELETE") {
    h = { ...h, ...csrfTokenHeader() };
  }

  if (!isEmpty(meta.body)) {
    h["Content-Type"] = "application/json";
  }

  if (isPlainObject(meta.headers)) {
    return h;
  } else {
    return { ...h, ...meta.headers };
  }
};

const defaultTransformer = async (action, state, res) => {
  if (res.status == 204) {
    return null;
  }

  return res.json().then((json) => json);
};

const createApiAction = ({
  baseType,
  endpoint,
  method = "GET",
  transformPayload = defaultTransformer,
}) => {
  const types = ["REQUEST", "SUCCESS", "FAILURE"].map(
    (t) => `${baseType}/${t}`,
  );

  if (fetch.polyfill) {
    endpoint = flow(
      endpoint,
      // Appends cache busting param to polyfilled fetch requests for IE11 compat
      bustCache,
    );
  }

  const actionCreator = (meta) => {
    return {
      [RSAA]: {
        method,
        endpoint: endpoint(meta),
        types: [
          { type: `${baseType}/REQUEST`, meta },
          {
            type: `${baseType}/SUCCESS`,
            payload: transformPayload,
            meta: meta,
          },
          { type: `${baseType}/FAILURE`, meta },
        ],
        credentials: "same-origin",
        headers: headers(method, meta),
        body: maybeSerializeBodyAsJson(meta),
      },
    };
  };

  actionCreator.REQUEST = types[0];
  actionCreator.SUCCESS = types[1];
  actionCreator.FAILURE = types[2];

  return actionCreator;
};

const createApiResultReducer = (
  { REQUEST, SUCCESS, FAILURE },
  options = {},
) => {
  const defaultState = {
    data: options.defaultData || {},
    loading: false,
    error: null,
    pagination: options.pagination ? { page: 1 } : {},
    meta: {},
  };

  return (state = defaultState, action) => {
    switch (action.type) {
      case REQUEST: {
        return {
          ...state,
          data: options.resetOnRequest ? defaultState.data : state.data,
          loading: true,
          error: null,
          meta: action.meta,
        };
      }
      case SUCCESS: {
        let payload = options.nestedEntries
          ? get(action.payload, options.nestedEntries)
          : action.payload;

        const dataKey = options.dataKey || "data";
        const moreUrlKey = options.moreUrlKey || "loadMoreUrl";
        const loadMoreUrl = get(payload, moreUrlKey);

        if (get(payload, dataKey)) {
          payload = get(payload, dataKey);
        }
        const data = options.indexBy
          ? indexBy(payload, options.indexBy)
          : payload;

        return {
          ...state,
          data,
          loading: false,
          pagination: options.pagination
            ? {
                ...action.payload.pagination,
                limit: get(action, "payload.limit"),
                total: get(action, "payload.total"),
                offset: get(action, "payload.offset"),
              }
            : null,
          meta: action.meta,
          loadMoreUrl: loadMoreUrl,
        };
      }
      case FAILURE: {
        return {
          ...state,
          data: {},
          loading: false,
          error: action.payload,
        };
      }
      default:
        return state;
    }
  };
};

// Adapt redux-api-middleware action creators to be used as onSubmit in reduxForm
// @example
//   submitApi((values, props) => ({ /* my payload */ }))(persistItem)
const submitApi =
  (valuesAndPropsToMeta, errorsTransformer = null) =>
  (actionCreator) =>
  async (values, dispatch, props) => {
    const response = await dispatch(
      actionCreator(valuesAndPropsToMeta(values, props)),
    );
    if (response.error) {
      const [errorMessage, errors] = errorsTransformer
        ? errorsTransformer(response.payload)
        : [I18n.t("js.generic_error"), {}];

      throw new SubmissionError({
        originalError: response.payload,
        _error: errorMessage,
        ...errors,
      });
    }
    return response.payload;
  };

export { createApiAction, indexBy, createApiResultReducer, submitApi };
