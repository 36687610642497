import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import styles from "./showFields.module.css";

function Statistics({ value }) {
  return !isEmpty(value) ? (
    <span
      className={styles.Statistics}
      title={I18n.t("js.apps.properties.statistics.view_count", {
        view_count: value.view_count,
      })}
    >
      <i className="fa fa-eye" />
      {value.view_count}
    </span>
  ) : null;
}
Statistics.propTypes = {
  value: PropTypes.shape({
    view_count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

export default Statistics;
