import { fetchWidgetbar } from "actions/administration/widgetbar";
import { createApiResultReducer } from "helpers/api";
import { combineReducers } from "redux";
import editWidgetbarReducer from "reducers/administration/widgetbar/edit";
import errorsReducer from "reducers/administration/widgetbar/errors";
import indexReducer from "reducers/administration/widgetbar/index";

export default combineReducers({
  fetchWidgetbar: createApiResultReducer(fetchWidgetbar),
  edit: editWidgetbarReducer,
  errors: errorsReducer,
  index: indexReducer,
});
