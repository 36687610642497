import { createApiAction, parseHeader } from "helpers/api";
import { stringify } from "query-string";
import { each, get } from "lodash";
import { createFormAction } from "redux-form-saga";

import { appSelector } from "selectors/appCreator";
import { appUrl, itemUrl } from "components/appCreator/api";

const parseArchivedPerColumn = (header) => {
  const archivedPerColumn = {};
  each(header.split(","), (item) => {
    const info = item.split("=");
    archivedPerColumn[info[0]] = info[1];
  });
  return archivedPerColumn;
};

export const NAMESPACE = "appCreator";

const transformHeaders = (_action, state, res) =>
  res.json().then((json) => {
    if (get(appSelector(state), "collection_layout.layout") === "wall") {
      let payload = { data: json };

      if (res.headers && res.headers.get("Link")) {
        payload["loadMoreUrls"] = parseHeader(res.headers.get("Link"));
      }

      if (res.headers && res.headers.get("X-Total-Archived-Per-Column")) {
        payload["totalArchivedItems"] = parseArchivedPerColumn(
          res.headers.get("X-Total-Archived-Per-Column"),
        );
      }

      return payload;
    } else {
      return {
        data: json,
        pagination: {
          nextPage: res.headers.get("X-Next-Page"),
          page: res.headers.get("X-Page"),
          perPage: res.headers.get("X-Per-Page"),
          prevPage: res.headers.get("X-Prev-Page"),
          total: res.headers.get("X-Total"),
          totalPages: res.headers.get("X-Total-Pages"),
        },
        totalArchivedItems: res.headers.get("X-Total-Archived"),
      };
    }
  });

export const fetchAppLink = createApiAction({
  method: "GET",
  endpoint: ({ appSlug, groupSlug }) =>
    `/${groupSlug ? `groups/${groupSlug}/` : ""}apps/${appSlug}`,
  baseType: `${NAMESPACE}/APP_LINK`,
});

export const fetchApp = createApiAction({
  method: "GET",
  endpoint: appUrl,
  baseType: `${NAMESPACE}/APP`,
});

export const subscribeApp = createApiAction({
  method: "PUT",
  endpoint: ({ appId, externalAppId }) =>
    `${appUrl({ appId, externalAppId })}/subscription`,
  baseType: `${NAMESPACE}/SUBSCRIBE`,
});

export const unsubscribeApp = createApiAction({
  method: "DELETE",
  endpoint: ({ appId, externalAppId }) =>
    `${appUrl({ appId, externalAppId })}/subscription`,
  baseType: `${NAMESPACE}/UNSUBSCRIBE`,
});

export const fetchItem = createApiAction({
  method: "GET",
  endpoint: itemUrl,
  baseType: `${NAMESPACE}/ITEM`,
});

export const archiveItem = createApiAction({
  method: "POST",
  endpoint: ({ appId, itemId, externalAppId }) =>
    `${appUrl({ appId, externalAppId })}/items/${itemId}/archive`,
  baseType: `${NAMESPACE}/ITEM/ARCHIVE`,
});

export const unarchiveItem = createApiAction({
  method: "POST",
  endpoint: ({ appId, itemId, externalAppId }) =>
    `${appUrl({ appId, externalAppId })}/items/${itemId}/unarchive`,
  baseType: `${NAMESPACE}/ITEM/UNARCHIVE`,
});

export const deleteItem = createApiAction({
  method: "DELETE",
  endpoint: ({ appId, itemId, externalAppId }) =>
    `${appUrl({ appId, externalAppId })}/items/${itemId}`,
  baseType: `${NAMESPACE}/ITEM/DELETE`,
});

export const fetchItems = createApiAction({
  method: "GET",
  endpoint: ({
    appId,
    view = "default",
    archived = false,
    tag,
    assignee_id,
    page,
    q,
    filters,
    externalAppId,
  }) => {
    let endpointUrl = `${appUrl({ appId, externalAppId })}/items?`;
    let queryParamsObject = {
      view: view,
      archived: archived,
      filters: filters,
      tag: tag,
      assignee_id: assignee_id,
      page: page,
      q: q,
    };

    return (
      endpointUrl +
      stringify(queryParamsObject, { skipNull: true, skipEmptyString: true })
    );
  },
  baseType: `${NAMESPACE}/ITEMS`,
  transformPayload: transformHeaders,
});

export const loadMoreItemsByColumn = createApiAction({
  method: "GET",
  endpoint: ({ url }) => `${url}`,
  baseType: `${NAMESPACE}/MORE_ITEMS`,
  transformPayload: transformHeaders,
});

export const createItem = createApiAction({
  method: "POST",
  endpoint: ({ appId, externalAppId }) =>
    `${appUrl({ appId, externalAppId })}/items`,
  baseType: `${NAMESPACE}/ITEM/CREATE`,
});

export const updateItem = createApiAction({
  method: "PUT",
  endpoint: itemUrl,
  baseType: `${NAMESPACE}/ITEM/UPDATE`,
});

export const saveItem = createFormAction(`${NAMESPACE}/ITEM/SAVE`);
export const saveItem2 = createApiAction({
  method: "PUT",
  endpoint: itemUrl,
  baseType: `${NAMESPACE}/MOVE_ITEM`,
});

export const moveItem = createApiAction({
  method: "PUT",
  endpoint: ({ appId, externalAppId, itemId, externalItemId }) =>
    `${itemUrl({ appId, externalAppId, itemId, externalItemId })}`,
  baseType: `${NAMESPACE}/MOVE_ITEM`,
});

export const exportItems = createApiAction({
  method: "POST",
  endpoint: ({ appSlug, groupSlug }) =>
    `/${groupSlug ? `groups/${groupSlug}/` : ""}apps/${appSlug}/items/exports`,
  baseType: `${NAMESPACE}/EXPORT_ITEMS`,
});

export const exportItemsGetLast = createApiAction({
  method: "GET",
  endpoint: ({ appSlug, groupSlug }) =>
    `/${
      groupSlug ? `groups/${groupSlug}/` : ""
    }apps/${appSlug}/items/exports/last`,
  baseType: `${NAMESPACE}/EXPORT_ITEMS_GET_LAST`,
});

export const fetchRelationOptions = createApiAction({
  method: "GET",
  endpoint: ({ appId, externalAppId, propertyName, q }) =>
    `${appUrl({ appId, externalAppId })}/options/${propertyName}?${stringify({
      q: `${q}*`,
    })}`,
  baseType: `${NAMESPACE}/APP/OPTIONS`,
});
