import { SubmissionError } from "redux-form";
import { compact, reduce } from "lodash";

// formats the given api error and throws redux-form SubmissionError
//
// @prop responseErrors [Array<Object>] the given api errors
// @prop options [Object] (optional) e.g. key_prefix to prefix a key
// @throws [SubmissionError]
//
// @example
// handleItemApiError([
//   {
//     property_name: "first_name",
//     t: ["apps.value.cant_be_empty", { property: "Vorname" }],
//   },
// ]);
// => {first_name: 'Dieses Feld muss ausgefüllt werden.'}
//
const handleItemApiError = (responseErrors, options = {}) => {
  const { key_prefix } = options;

  const submitErrors = reduce(
    responseErrors,
    (errors, error) => {
      const key = compact([key_prefix, error["property_name"]]).join(".");
      errors[key] = I18n.t.apply(I18n, error["t"]);
      return errors;
    },
    {},
  );

  throw new SubmissionError(submitErrors);
};

export default handleItemApiError;
