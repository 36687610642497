import { createSelector } from "reselect";
import { get } from "lodash";

export const currentRouteSelector = (state) =>
  get(state, "navigation.currentRoute");

export const currentRouteParamsSelector = (state) =>
  get(currentRouteSelector(state), "params");

export const currentGroupSlugSelector = (state) =>
  get(currentRouteParamsSelector(state), "groupSlug");

export const currentAppSlugSelector = (state) =>
  get(currentRouteParamsSelector(state), "appSlug");

export const currentPageSelector = (state) =>
  get(currentRouteParamsSelector(state), "page");

export const currentAppUrlSelector = createSelector(
  [currentGroupSlugSelector, currentAppSlugSelector],
  (groupSlug, appSlug) =>
    `/${groupSlug ? `groups/${groupSlug}/` : ""}apps/${appSlug}/items`,
);
