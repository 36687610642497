import PropTypes from "prop-types";
import React from "react";
import { Field } from "redux-form";
import { get } from "lodash";

import styles from "./editFields.module.css";
import SimpleInput from "components/appCreator/items/form/SimpleInput";

const extractUrl = (value) => get(value, "url") || "";
const normalizeValue = (value) => ({ url: value });

function UrlField({ name, required, disabled, id }) {
  return (
    <Field
      component={SimpleInput}
      type="url"
      className={`${styles.Url} property-${name} form-input`}
      name={name}
      required={required}
      format={extractUrl}
      normalize={normalizeValue}
      disabled={disabled}
      id={id}
    />
  );
}
UrlField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

export default UrlField;
