import React from "react";
import { useMutation, UseMutationResult } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import classNames from "classnames";
import invariant from "invariant";
import { Group, State } from "../../@types";
import { useDispatch, useSelector } from "react-redux";
import { toggleSubscribed } from "../../reducers/groups";

type SubscriptionStatus = {
  subscription_status: boolean;
} | null;
type MutateVariables = {
  subscription_status: boolean /* Wether to set or unset favorite status */;
};

function useToggleGroupSubscribe(
  groupSlug: string,
): [
  Group,
  UseMutationResult<SubscriptionStatus, unknown, MutateVariables, unknown>,
] {
  const group = useSelector((state: State) => state.groups.bySlug[groupSlug]);
  const dispatch = useDispatch();
  invariant(group, `Group ${groupSlug} not preloaded`);

  const mutation = useMutation<SubscriptionStatus, unknown, MutateVariables>({
    mutationFn({ subscription_status }) {
      return fetchApi(`/groups/${groupSlug}/subscribe`, {
        method: subscription_status ? "POST" : "DELETE",
      });
    },
    onSuccess(data) {
      dispatch(
        toggleSubscribed({
          groupSlug,
          subscription_status: data?.subscription_status || false,
        }),
      );
    },
  });

  return [group, mutation];
}

export default function ToggleGroupSubscribe({
  groupSlug,
}: {
  groupSlug: string;
}) {
  const [{ subscription_status }, { mutate, isLoading }] =
    useToggleGroupSubscribe(groupSlug);

  return (
    <li
      data-bs-toggle="tooltip"
      title={
        subscription_status
          ? I18n.t("js.groups.group.unsubscribe_group")
          : I18n.t("js.groups.group.subscribe_group")
      }
      onClick={(e: React.MouseEvent<HTMLLIElement>) => {
        e.preventDefault();
        e.stopPropagation();
        !isLoading && mutate({ subscription_status: !subscription_status });
      }}
    >
      <a
        href="#"
        className={classNames({ "text-muted cursor-wait": isLoading })}
      >
        {subscription_status
          ? I18n.t("js.groups.group.unsubscribe_group")
          : I18n.t("js.groups.group.subscribe_group")}
      </a>
    </li>
  );
}
