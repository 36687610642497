import React from "react";
import classNames from "classnames";

interface UploadBarTypes {
  state: "uploading" | "volatile" | "published" | "failed" | "quarantine";
  progress: number;
}

function UploadBar({ progress, state }: UploadBarTypes) {
  return (
    <div
      className={classNames("bar", {
        "bar-success": state === "volatile" || state === "published",
        "bar-danger": state === "failed" || state === "quarantine",
      })}
      style={{
        width: `${state === "uploading" ? progress : 100}%`,
      }}
    />
  );
}

export default UploadBar;
