import React, { createContext, Fragment, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  map,
  castArray,
  isArray,
  compact,
  debounce,
  startsWith,
  isEmpty,
} from "lodash";
import { Field } from "redux-form";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";

import styles from "./editFields.module.css";
import { fetchTags } from "actions/tags";
import FieldError from "components/appCreator/items/form/FieldError";

const normalizeValue = (value) => {
  if (value === null) return null; // When nothing is selected
  if (isArray(value)) return map(value, normalizeValue);

  return {
    id: value.value,
    name: value.label,
  };
};

const formatValue = (state) =>
  map(compact(castArray(state)), ({ id, name }) => ({
    value: id,
    label: name,
  }));

function formatCreateLabel(label) {
  return (
    <span>
      <i className="fa fa-plus" /> {label}
    </span>
  );
}

function SelectAdapter({
  input,
  meta: { error },
  required,
  id,
  disabled,
  className,
  internal,
  multiple,
  allowCreate,
}) {
  const tagParentsFromContext = useContext(TagParentsContext);
  if (internal && isEmpty(tagParentsFromContext))
    console.error(
      "TagField is supposed to be internal, but no tag parents were provided via TagParentsContext",
    );
  const tagParents =
    internal && !isEmpty(tagParentsFromContext)
      ? tagParentsFromContext.join(",")
      : null;

  const dispatch = useDispatch();
  const loadOptions = useMemo(
    () =>
      debounce(async (value, callback) => {
        const { payload } = await dispatch(
          fetchTags({
            q: value,
            tagParents,
            excludeNew: true,
          }),
        );
        callback(formatValue(payload));
      }, 375),
    [tagParents, input.name],
  );

  let props = {
    ...input,
    // workaround for mobile,
    // see ticket #9930 or issue https://github.com/JedWatson/react-select/issues/2692#issuecomment-395743446
    onBlur: (e) => e.preventDefault(),
    isClearable: !required,
    isDisabled: disabled,
    inputId: id,
    placeholder: I18n.t("js.tag_select.placeholder"),
    noOptionsMessage: ({ inputValue }) =>
      isEmpty(inputValue)
        ? I18n.t("plugins.select2.enter_more_characters", { count: 1 })
        : I18n.t("plugins.select2.no_match"),
    className,
    loadOptions: (value, callback) => {
      loadOptions(value, callback);
    },
    formatOptionLabel: ({ value, label }) =>
      startsWith(value, "new") ? formatCreateLabel(label) : label,
    styles: { menu: (base) => ({ ...base, zIndex: 999 }) },
    isMulti: multiple,
    // creation props
    formatCreateLabel: formatCreateLabel,
    createOptionPosition: "first",
    onCreateOption: (value) => {
      const newValue = {
        label: value,
        value: `new_${value}`,
        new: true,
      };
      input.onChange([...input.value, newValue]);
    },
  };

  const SelectComponent = allowCreate ? AsyncCreatableSelect : AsyncSelect;

  return (
    <Fragment>
      <SelectComponent {...props} classNamePrefix={"Select"} />
      {error && <FieldError error={error} />}
    </Fragment>
  );
}
SelectAdapter.propTypes = {
  input: PropTypes.object,
  required: PropTypes.bool,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  allowCreate: PropTypes.bool,
  className: PropTypes.string,
  meta: PropTypes.shape({ error: FieldError.propTypes.error }),
};

export const TagParentsContext = createContext(null);

function TagField({
  name,
  required,
  internal,
  disabled,
  id,
  appId,
  allowCreate = true,
  multiple = true,
  tagParents,
}) {
  const field = (
    <Field
      component={SelectAdapter}
      name={name}
      required={required}
      disabled={disabled}
      normalize={normalizeValue}
      multiple={multiple}
      allowCreate={allowCreate}
      format={formatValue}
      id={id}
      props={{
        className: `${styles.Tag} property-${name}`,
        internal,
      }}
    />
  );
  if (appId) {
    console.warn(
      "TagField appId prop is deprecated, " +
        "please use tagParents prop or TagParentsContext.Provider instead",
    );
    return (
      <TagParentsContext.Provider value={[`app:${appId}`]}>
        {field}
      </TagParentsContext.Provider>
    );
  }

  if (tagParents) {
    return (
      <TagParentsContext.Provider value={tagParents}>
        {field}
      </TagParentsContext.Provider>
    );
  }

  return field;
}
TagField.propTypes = {
  name: PropTypes.string.isRequired,
  tagParents: PropTypes.arrayOf(PropTypes.string),
  ...SelectAdapter.propTypes,
};

export default TagField;
