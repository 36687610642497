import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import preventDefault from "helpers/components/preventDefault";

function FolderInfo({
  id,
  name,
  type,
  profile,
  navigateDisabled,
  handleNavigate,
  noExtraColspan,
  extraColumns,
  context,
}) {
  return (
    <Fragment>
      <td className="col-name p-2">
        {navigateDisabled ? (
          <span className="text-muted">{name}</span>
        ) : (
          <a
            href={
              handleNavigate && context === "modal"
                ? "#"
                : `/files/folders/${id}`
            }
            onClick={
              context === "modal"
                ? preventDefault(() => handleNavigate())
                : handleNavigate
            }
            data-id={id}
            data-type={type}
          >
            {name}
          </a>
        )}
      </td>
      {map(extraColumns, ({ Data }, i) => (
        <Data key={i} profile={profile} />
      ))}
      {noExtraColspan ? null : <td colSpan={4} />}
    </Fragment>
  );
}

FolderInfo.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  profile: PropTypes.object,
  navigateDisabled: PropTypes.bool,
  handleNavigate: PropTypes.func,
  noExtraColspan: PropTypes.bool,
  extraColumns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.func.isRequired,
      Data: PropTypes.func.isRequired,
    }),
  ),
};

export default FolderInfo;
