import React, { useEffect, useRef } from "react";
import Tooltip from "bootstrap/js/dist/tooltip";

export default function useTooltip<T extends HTMLElement>(
  deps: React.DependencyList = [],
) {
  const elemRef = useRef<T>(null);

  useEffect(() => {
    if (elemRef.current) {
      Tooltip.getOrCreateInstance(elemRef.current);
    }

    return function () {
      if (elemRef.current) Tooltip.getInstance(elemRef.current)?.dispose();
    };
  }, [elemRef.current, ...deps]);

  return elemRef;
}
