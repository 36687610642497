import PropTypes from "prop-types";
import React from "react";
import { Field } from "redux-form";

import FileUploadField from "components/shared/fields/FileUploadField";
import styles from "./editFields.module.css";

function FileField({ name, required, multiple, disabled }) {
  return (
    <Field
      className={styles.File}
      name={name}
      required={required}
      component={FileUploadField}
      multiple={multiple}
      disabled={disabled}
    />
  );
}
FileField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FileField;
