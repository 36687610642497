import React from "react";
import classnames from "classnames";
import { isFunction } from "lodash";

import theme from "components/shared/markdownEditor/editorPlugins/buttons/theme";

const preventDefault = (e) => e.preventDefault();
interface ToolbarButtonProps {
  isActive(): boolean;
  isDisabled(): boolean;
  onClick(...args: unknown[]): unknown;
  title: (() => string) | string;
  children: React.ReactNode;
}
function ToolbarButton({
  isActive,
  onClick,
  title,
  children,
  isDisabled,
}: ToolbarButtonProps) {
  return (
    <div className={theme.buttonWrapper} onMouseDown={preventDefault}>
      <button
        className={classnames(theme.button, {
          [theme.active]: isActive ? isActive() : false,
        })}
        disabled={isDisabled ? isDisabled() : false}
        onClick={onClick}
        type="button"
        title={isFunction(title) ? title() : title}
      >
        {children}
      </button>
    </div>
  );
}

export default ToolbarButton;
