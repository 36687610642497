import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useMutation, UseMutationResult } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import useTooltip from "../../hooks/useTooltip";
import classNames from "classnames";
import invariant from "invariant";
import { Group, State } from "../../@types";
import { useDispatch, useSelector } from "react-redux";
import { toggleFavorited } from "../../reducers/groups";

type FavoriteStatus = {
  favorited: boolean;
} | null;
type MutateVariables = {
  favorite: boolean /* Wether to set or unset favorite status */;
};

function useToggleGroupFavorite(
  groupSlug: string,
): [
  Group,
  UseMutationResult<FavoriteStatus, unknown, MutateVariables, unknown>,
] {
  const group = useSelector((state: State) => state.groups.bySlug[groupSlug]);
  const dispatch = useDispatch();
  invariant(group, `Group ${groupSlug} not preloaded`);

  const mutation = useMutation<FavoriteStatus, unknown, MutateVariables>({
    mutationFn({ favorite }) {
      return fetchApi(`/api/v1/groups/${groupSlug}/favorite`, {
        method: favorite ? "POST" : "DELETE",
      });
    },
    onSuccess(data) {
      dispatch(
        toggleFavorited({
          groupSlug,
          favorited: data?.favorited || false,
        }),
      );
    },
  });

  return [group, mutation];
}

export default function ToggleGroupFavoriteButton({
  groupSlug,
}: {
  groupSlug: string;
}) {
  const [{ favorited }, { mutate, isLoading }] =
    useToggleGroupFavorite(groupSlug);
  const tooltipRef = useTooltip<HTMLButtonElement>([favorited]);

  return (
    <button
      ref={tooltipRef}
      data-bs-toggle="tooltip"
      title={
        favorited
          ? I18n.t("js.page_title.remove_group_from_favorites")
          : I18n.t("js.page_title.add_group_to_favorites")
      }
      className={classNames("btn btn-sm", {
        "cursor-wait": isLoading,
        "text-favorite hover:text-favorite": favorited,
        "text-gray-200 hover:text-favorite/50": !favorited,
      })}
      disabled={isLoading}
      onClick={() => mutate({ favorite: !favorited })}
    >
      <FontAwesomeIcon icon={favorited ? solid("star") : regular("star")} />
    </button>
  );
}
