import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import GenericValueList from "./helpers/GenericValueList";

// Don't link selection values by default
// Provide url function via SelectionUrlContext if you want to link them
export function selectionFilterUrl() {
  return null;
}
export const SelectionUrlContext = createContext(selectionFilterUrl);

function MaybeLink({ href, children }) {
  return href ? <a href={href}>{children}</a> : children;
}
MaybeLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function Selection({ displayValue, property }) {
  const selectionFilterUrl = useContext(SelectionUrlContext);

  return (
    <GenericValueList
      values={displayValue}
      component={(value) => (
        <MaybeLink href={selectionFilterUrl({ ...value, property })}>
          {get(value, ["displayValue"]) || get(value, ["label"]) || value}
        </MaybeLink>
      )}
    />
  );
}
Selection.propTypes = {
  displayValue: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
    ),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  property: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

export default Selection;
