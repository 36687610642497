import { combineReducers } from "redux";

import { createApiResultReducer } from "helpers/api";
import { fetchMembership, fetchMembershipSettings } from "actions/memberships";

const reducer = combineReducers({
  membershipRequest: createApiResultReducer(fetchMembership, {
    resetOnRequest: true,
  }),
  membershipSettingsRequest: createApiResultReducer(fetchMembershipSettings, {
    resetOnRequest: true,
  }),
});

export default reducer;
