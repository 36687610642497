import React from "react";
import PropTypes from "prop-types";

import Address from "components/appCreator/properties/edit/AddressField";
import Email from "components/appCreator/properties/edit/EmailField";
import Image from "components/appCreator/properties/edit/ImageField";
import MembershipRelation from "components/appCreator/properties/edit/MembershipField";
import Number from "components/appCreator/properties/edit/NumberField";
import Selection from "components/appCreator/properties/edit/SelectionField";
import Text from "components/appCreator/properties/edit/TextField";
import Url from "components/appCreator/properties/edit/UrlField";
import Tag from "components/appCreator/properties/edit/TagField";
import DatePicker from "components/appCreator/properties/edit/DateField";
import Html from "components/appCreator/properties/edit/HtmlField";
import File from "components/appCreator/properties/edit/FileField";
import RelationField from "components/appCreator/properties/edit/RelationField";

const PROPERTIES = {
  address: Address,
  email: Email,
  image: Image,
  membership: MembershipRelation,
  selection: Selection,
  text: Text,
  url: Url,
  number: Number,
  tag: Tag,
  file: File,
  date: DatePicker,
  html: Html,
  relation: RelationField,
};

export function isManualFocusHandlingRequired(prop) {
  return prop.type === "text" && prop.multiline;
}

function canUpdate(property, item) {
  const can = item?.can || property.can || {};

  if (`update[${property.name}]` in can) return can[`update[${property.name}]`];
  if ("update" in can) return can.update;

  return true;
}

function PropertyEdit({ property, id, appId, inputRef, item }) {
  const { name, type } = property;
  const Component = PROPERTIES[type];

  if (Component) {
    return (
      <Component
        {...property}
        disabled={!canUpdate(property, item)}
        id={id}
        appId={appId} // deprecated
        inputRef={inputRef}
      />
    );
  } else {
    return (
      <p>
        Unsupported field type &quot;{type}&quot; for property &quot;{name}
        &quot;
      </p>
    );
  }
}

PropertyEdit.propTypes = {
  property: PropTypes.object.isRequired,
  value: PropTypes.any,
  appId: PropTypes.string,
  id: PropTypes.string,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

export default PropertyEdit;
