import React from "react";

interface MarkdownTranslationTypes {
  translation: string;
  styles?: string;
  options?: Record<PropertyKey, unknown>;
}

function MarkdownTranslation({
  translation,
  styles,
  options = {},
}: MarkdownTranslationTypes) {
  return (
    <div
      className={styles}
      dangerouslySetInnerHTML={{
        __html: Application.markdown(I18n.t(translation, options)),
      }}
    ></div>
  );
}

export default MarkdownTranslation;
