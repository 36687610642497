import { combineReducers } from "redux";

import { fetchFolder, FOLDER } from "actions/files";

const currentFolder = (state = null, action) => {
  switch (action.type) {
    case FOLDER.SELECT:
      return { id: action.meta.id, namespace: action.meta.namespace };
    default:
      return state;
  }
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case fetchFolder.REQUEST:
      return {
        ...state,
        [action.meta.id]: {
          ...state[action.meta.id],
          loading: true,
          error: null,
          meta: action.meta,
        },
      };
    case fetchFolder.SUCCESS:
      return {
        ...state,
        [action.meta.id]: {
          ...state[action.meta.id],
          data: action.payload,
          loading: false,
          meta: action.meta,
        },
      };
    case fetchFolder.FAILURE:
      return {
        ...state,
        [action.meta.id]: {
          ...state[action.meta.id],
          data: {},
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

const reducer = combineReducers({
  byId,
  currentFolder,
});

export default reducer;
