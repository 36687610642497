import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ColorObject,
  Theme,
} from "components/administration/designManager/types";
import { defaultTheme } from "components/administration/designManager/defaultThemes";
import { loadOverrideTheme } from "components/administration/designManager/useTheme";

export type ThemeState = Theme;

const initialState: ThemeState =
  loadOverrideTheme() || window.Preload?.theme || defaultTheme;

export const reducers = ({ override }: { override: boolean }) => ({
  setTheme(state, action: PayloadAction<Theme>) {
    return { ...action.payload, override };
  },
  setUserbarIconType(state, action: PayloadAction<Theme["userbarIconType"]>) {
    state.userbarIconType = action.payload;
    state.override = override;
  },
  setBannerImage(state, action: PayloadAction<Theme["bannerImage"]>) {
    state.bannerImage = action.payload;
    state.override = override;
  },
  setLogoImage(state, action: PayloadAction<Theme["logoImage"]>) {
    state.logoImage = action.payload;
    state.override = override;
  },
  setFaviconImage(state, action: PayloadAction<Theme["faviconImage"]>) {
    state.faviconImage = action.payload;
    state.override = override;
  },
  setEmailLogoImage(state, action: PayloadAction<Theme["emailLogoImage"]>) {
    state.emailLogoImage = action.payload;
    state.override = override;
  },
  changeColor(state, action: PayloadAction<[string, ColorObject | null]>) {
    const [name, colorObj] = action.payload;
    if (!state.colors) state.colors = {};
    if (colorObj) state.colors[name] = colorObj;
    else delete state.colors[name];
    state.override = override;
  },
  mergeColors(state, action: PayloadAction<Record<string, ColorObject>>) {
    state.colors = { ...state.colors, ...action.payload };
    state.override = override;
  },
  setHeaderVariant(
    state,
    action: PayloadAction<Pick<Theme, "logoType" | "bannerType">>,
  ) {
    state.logoType = action.payload.logoType;
    state.bannerType = action.payload.bannerType;
    state.override = override;
  },
  setCustomCss(state, action: PayloadAction<Theme["customCss"]>) {
    state.customCss = action.payload;
    state.override = override;
  },
  setFullWidth(state, action: PayloadAction<Theme["fullWidth"]>) {
    state.fullWidth = action.payload;
    state.override = override;
  },
});

const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    ...reducers({ override: true }),
    resetTheme() {
      return window.Preload?.theme || defaultTheme;
    },
  },
});

export const {
  setTheme,
  resetTheme,
  setUserbarIconType,
  setBannerImage,
  setLogoImage,
  setFaviconImage,
  setEmailLogoImage,
  changeColor,
  mergeColors,
  setHeaderVariant,
  setCustomCss,
  setFullWidth,
} = themeSlice.actions;
export default themeSlice.reducer;
