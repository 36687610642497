// Copy from https://github.com/jonschlinkert/remarkable/blob/master/lib/rules_inline/text.js
// to enable custom terminator chars like '#' for tixxt style hashtags

// Skip text characters for text token, place those to pending buffer
// and increment current pos
//
// Rule to skip pure text
// '{}$%@~+=:#' reserved for extentions

function isTerminatorChar(ch) {
  switch (ch) {
    case 0x0a /* \n */:
    case 0x5c /* \ */:
    case 0x60 /* ` */:
    case 0x2a /* * */:
    case 0x5f /* _ */:
    case 0x5e /* ^ */:
    case 0x5b /* [ */:
    case 0x5d /* ] */:
    case 0x21 /* ! */:
    case 0x26 /* & */:
    case 0x3c /* < */:
    case 0x3e /* > */:
    case 0x7b /* { */:
    case 0x7d /* } */:
    case 0x24 /* $ */:
    case 0x25 /* % */:
    case 0x40 /* @ */:
    case 0x7e /* ~ */:
    case 0x2b /* + */:
    case 0x3d /* = */:
    case 0x3a /* : */:
    case 0x23 /* # */:
      return true;
    default:
      return false;
  }
}

const customText = (state, silent) => {
  let pos = state.pos;

  while (pos < state.posMax && !isTerminatorChar(state.src.charCodeAt(pos))) {
    pos++;
  }

  if (pos === state.pos) {
    return false;
  }

  if (!silent) {
    state.pending += state.src.slice(state.pos, pos);
  }

  state.pos = pos;

  return true;
};

const customizedTextParser = (md) => {
  md.inline.ruler.at("text", customText);
};

export default customizedTextParser;
