import React from "react";

export default function LeaveGroupOption({ groupSlug }: { groupSlug: string }) {
  return (
    <li>
      <a
        href={`/groups/${groupSlug}/join`}
        data-method={"delete"}
        data-confirm={I18n.t("js.directory.group_row.leave_group_confirmation")}
      >
        {I18n.t("js.directory.group_row.leave_group")}
      </a>
    </li>
  );
}
