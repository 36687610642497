import classNames from "classnames";
import React from "react";

type Props = {
  name: string;
  href: string;
  isReaderTag?: boolean;
};
export default function Tag({ name, href, isReaderTag }: Props) {
  return (
    <a
      href={href}
      className={classNames("tag", { "tag--reader": isReaderTag })}
    >
      {name}
    </a>
  );
}
