import { reduce, filter, map, isString } from "lodash";

const parserRegex = /\s*(.+)\s*=\s*"(.+)"/;

export function parseLink(link) {
  const parts = link.split(";");
  const result = {
    url: parts
      .shift()
      .trim()
      .replace(/(^<|>$)/g, ""),
  };
  return parts.reduce((acc, part) => {
    const match = part.match(parserRegex);
    if (match) {
      return { ...acc, [match[1]]: match[2] };
    }
    return acc;
  }, result);
}

export default function parseHeader(header) {
  if (!isString(header)) {
    return {};
  }
  return reduce(
    filter(map(header.split(","), parseLink), (x) => x && x.rel),
    (acc, x) => ({ ...acc, [x.rel]: x.url }),
    {},
  );
}
