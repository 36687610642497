import PropTypes from "prop-types";
import React from "react";
import { Field } from "redux-form";
import { isString, isNumber } from "lodash";

import styles from "./editFields.module.css";
import SimpleInput from "components/appCreator/items/form/SimpleInput";

const round = (value, precision = 0) => {
  if (isNumber(value)) return value.toFixed(precision);
  if (isString(value)) return Number.parseFloat(value).toFixed(precision);

  return value;
};

function NumberInput({ input, precision, ...otherProps }) {
  return (
    <SimpleInput
      {...otherProps}
      input={input}
      onBlur={(e) => {
        input.onChange(round(e.target.value, precision) * 1);
        input.onBlur(e);
      }}
    />
  );
}

NumberInput.propTypes = {
  precision: PropTypes.number,
  input: PropTypes.shape({
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

function NumberField({
  name,
  required,
  id,
  prefix,
  postfix,
  precision,
  disabled,
  max,
  min,
}) {
  return (
    <Field
      className={`property-${name} form-input ${styles.Numbe}`}
      component={NumberInput}
      name={name}
      type="number"
      required={required}
      id={id}
      max={max}
      min={min}
      props={{ precision }}
      disabled={disabled}
      prefix={prefix}
      postfix={postfix}
    />
  );
}

NumberField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  id: PropTypes.string,
  prefix: PropTypes.string,
  postfix: PropTypes.string,
  precision: PropTypes.number,
  disabled: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
};

export default NumberField;
