import { getDefaultKeyBinding, RichUtils } from "draft-js";

export default function createShortcutsPlugin() {
  return {
    handleKeyCommand: (
      command,
      _editorState,
      _,
      { getEditorState, setEditorState },
    ) => {
      const newEditorState = RichUtils.handleKeyCommand(
        getEditorState(),
        command,
      );

      if (newEditorState) {
        setEditorState(newEditorState);
        return "handled";
      }

      return "not-handled";
    },
    keyBindingFn: (e) => {
      return getDefaultKeyBinding(e);
    },
  };
}
