import { createApiResultReducer } from "helpers/api";
import { fetchParticipations } from "actions/participations";
import { get, each } from "lodash";

const apiReducer = createApiResultReducer(fetchParticipations, {
  resetOnRequest: true,
  defaultData: [],
  moreUrlKey: "loadMoreUrl.next",
});

const reducer = (state = {}, action) => {
  if (
    action.type !== "/PARTICIPATIONS/REQUEST" &&
    action.type !== "/PARTICIPATIONS/SUCCESS"
  ) {
    switch (action.type) {
      case "/PARTICIPATIONS_MORE/SUCCESS": {
        let newData = state.data;
        each(get(action, ["payload", "data"]), (p) => newData.push(p));
        return {
          ...state,
          data: newData,
          loadMoreUrl: get(action, ["payload", "loadMoreUrl", "next"]) || null,
        };
      }
      default:
        return state;
    }
  }
  return apiReducer(state, action);
};

export default reducer;
