import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import { defaultImageUrl } from "./Image";
import GenericValueList from "./helpers/GenericValueList";

const fallbackImageUrl = `https://${location.host}/assets/misc/profile_50x50.jpg`;

function imageLoadingError({ currentTarget }) {
  currentTarget.onerror = null; // prevents looping
  if (currentTarget.src !== fallbackImageUrl)
    currentTarget.src = fallbackImageUrl;
}

function MemberLink({ id, imageId, displayValue, thumbnailUrl }) {
  const image = (
    <img
      className="rounded-full w-5 h-5"
      src={
        thumbnailUrl ||
        (isEmpty(imageId)
          ? fallbackImageUrl
          : defaultImageUrl(imageId, "50x50"))
      }
      onError={imageLoadingError}
      aria-hidden
    />
  );

  return (
    <>
      {id ? (
        <a className="flex gap-1 items-center" href={`/members/${id}`}>
          {image}
          <span className={"truncate"}>{displayValue}</span>
        </a>
      ) : (
        <span className="flex gap-1 items-center">
          {image}
          <span className={"truncate"}>
            {window.Preload.current_network.config.anonymize_membership ===
            "disabled"
              ? displayValue
              : window.Preload.current_network.config.anonymize_membership_name}
          </span>
        </span>
      )}
    </>
  );
}
// id is null if the underlying Membership is deleted
MemberLink.propTypes = {
  id: PropTypes.string,
  displayValue: PropTypes.string,
  imageId: PropTypes.string,
  thumbnailUrl: PropTypes.string, // for storybook
};

function MembershipRelation({ value, options }) {
  return (
    <GenericValueList values={value} component={MemberLink} {...options} />
  );
}
MembershipRelation.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape(MemberLink.propTypes)),
    PropTypes.shape(MemberLink.propTypes),
  ]),
};

export default MembershipRelation;
