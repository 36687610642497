import React from "react";
import PropTypes from "prop-types";

// TODO Application.markdown escapes colored text
function Text({ value, property = {} }) {
  return property.multiline ? (
    <div
      className="prose break-words"
      dangerouslySetInnerHTML={{
        __html: Application.markdown(value),
      }}
    />
  ) : (
    <div className="truncate">{value}</div>
  );
}

Text.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  property: PropTypes.shape({ multiline: PropTypes.bool }),
};
export default Text;
