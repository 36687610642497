export const mentionRule = (state, silent) => {
  let mentionStart, mentionEnd, mentionSeparator;
  let oldPos = state.pos;
  let start = state.pos;
  let max = state.posMax;

  const mentionMarker = state.src.charCodeAt(start);

  if (mentionMarker !== 0x40 /* @ */ && mentionMarker !== 0x23 /* # */) {
    return false;
  }
  if (state.src.charCodeAt(start + 1) !== 0x5b /* [ */) {
    return false;
  }
  if (state.level >= state.options.maxNesting) {
    return false;
  }

  state.pos = start + 1;
  while (state.pos < max) {
    const marker = state.src.charCodeAt(state.pos);
    if (marker === 0x5b /* [ */) {
      mentionStart = state.pos + 1;
    } else if (marker === 0x3a /* : */) {
      mentionSeparator = state.pos;
    } else if (marker === 0x5d /* ] */) {
      mentionEnd = state.pos;
      break;
    }

    state.parser.skipToken(state);
  }

  state.pos = oldPos;

  // parser failed to find ']', so it's not a valid mention
  if (mentionEnd < 0) {
    return false;
  }

  // We found the end of the mention, and know for a fact it's a valid mention;
  // so all that's left to do is to call tokenizer.
  if (!silent) {
    state.pos = mentionStart;
    state.posMax = mentionEnd;

    const id = state.src.substr(mentionStart, mentionSeparator - mentionStart);
    const name = state.src.substr(
      mentionSeparator + 1,
      mentionEnd - mentionSeparator - 1,
    );

    state.pos = mentionSeparator + 1;
    state.posMax = mentionEnd;

    state.push({
      type: "mention_open",
      level: state.level++,
      marker: String.fromCharCode(mentionMarker),
      id,
      name,
    });
    state.parser.tokenize(state);
    state.push({
      type: "mention_close",
      level: state.level--,
    });
  }

  state.pos = mentionEnd + 1;
  state.posMax = max;

  return true;
};

const markdownMentionsParser = (md) => {
  md.inline.ruler.push("mention", mentionRule);
};

export default markdownMentionsParser;
