const reParamSearch = /([?&])_=[^&]*/;
const reQueryString = /\?/;
let nonce = new Date().getTime();

// Search for a '_' parameter in the query string
// If it already exists then set the value with the current time
// Otherwise add a new '_' parameter to the end with the current time
//
// Inspired by https://github.com/github/fetch/issues/629#issuecomment-581652775
export default function bustCache(url) {
  if (reParamSearch.test(url)) {
    return url.replace(reParamSearch, "$1_=" + nonce++);
  }
  return `${url}${(reQueryString.test(url) ? "&" : "?") + "_=" + nonce++}`;
}
