import React from "react";
import PropTypes from "prop-types";
import { get, isEmpty, map } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function Breadcrumb({ folder, handleNavigate }) {
  return (
    <ul className="breadcrumb border-box mb-3 px-3 py-2 flex gap-3 align-center overflow-x-auto">
      {map(get(folder, ["folder_path"], []), (parentFolder, i) => (
        <li key={parentFolder.id} className="whitespace-nowrap">
          <a
            href={handleNavigate ? "#" : `/files/folders/${parentFolder.id}`}
            onClick={
              handleNavigate
                ? (e) => {
                    e.preventDefault();
                    handleNavigate({ id: parentFolder.id });
                  }
                : null
            }
            className={"mr-3"}
          >
            {i === 0 ? (
              <FontAwesomeIcon icon={solid("folder")} />
            ) : (
              parentFolder.name
            )}
          </a>
          <FontAwesomeIcon
            icon={solid("chevron-right")}
            className={"fa-sm text-muted"}
          />
        </li>
      ))}
      {folder ? (
        <li className="active text-muted">
          {isEmpty(get(folder, ["folder_path"])) ? (
            <FontAwesomeIcon icon={solid("folder")} className={"mr-2"} />
          ) : null}
          {folder.name}
        </li>
      ) : (
        <li>
          <i className="fa fa-spinner fa-spin" />
        </li>
      )}
    </ul>
  );
}
Breadcrumb.propTypes = {
  folder: PropTypes.shape({
    name: PropTypes.string.isRequired,
    folder_path: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }),
  handleNavigate: PropTypes.func,
};
