// Customized parser for underline
// _this is underline_
// Marker: 0x5f (_)

import { isAlphaNum } from "components/shared/markdownEditor/parserPlugins/helpers";

const underline = (state, silent) => {
  let found, stack, lastChar, nextChar;
  const max = state.posMax;
  const start = state.pos;

  if (state.src.charCodeAt(start) !== 0x5f /* _ */) {
    return false;
  }

  if (silent) {
    return false;
  }

  if (start + 2 >= max) {
    return false;
  }

  if (state.level >= state.options.maxNesting) {
    return false;
  }

  lastChar = start > 0 ? state.src.charCodeAt(start - 1) : -1;
  nextChar = state.src.charCodeAt(start + 1);

  if (isAlphaNum(lastChar)) {
    return false;
  }
  if (nextChar === 0x20 || nextChar === 0x0a) {
    return false;
  }

  state.pos = start + 1;
  stack = 1;

  while (state.pos < max) {
    if (state.src.charCodeAt(state.pos) === 0x5f /*_*/) {
      lastChar = state.src.charCodeAt(state.pos - 1);
      nextChar = state.pos + 1 < max ? state.src.charCodeAt(state.pos + 1) : -1;

      if (
        !isAlphaNum(nextChar) &&
        nextChar !== 0x5f &&
        lastChar !== 0x5f /*_*/
      ) {
        if (lastChar !== 0x20 /* SPACE */ && lastChar !== 0x0a /* \n */) {
          // closing '_'
          stack--;
        } else if (
          nextChar !== 0x20 /* SPACE */ &&
          nextChar !== 0x0a /* \n */
        ) {
          // opening '_'
          stack++;
        }
        if (stack <= 0) {
          found = true;
          break;
        } else {
          continue;
        }
      }
    }

    state.parser.skipToken(state);
  }

  if (!found) {
    // parser failed to find ending tag, so it's not valid underline
    state.pos = start;
    return false;
  }

  // found!
  state.posMax = state.pos;
  state.pos = start + 1;

  if (!silent) {
    state.push({ type: "underline_open", level: state.level++ });
    state.parser.tokenize(state);
    state.push({ type: "underline_close", level: --state.level });
  }

  state.pos = state.posMax + 1;
  state.posMax = max;

  return true;
};

const underlineParser = (md) => {
  md.inline.ruler.push("underline", underline);
};

export default underlineParser;
