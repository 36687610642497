import { reducers } from "components/administration/designManager/themeSlice";
import { createSlice } from "@reduxjs/toolkit";

const groupCategoryThemeSlice = createSlice({
  name: "groupCategoryTheme",
  initialState: {},
  reducers: {
    ...reducers({ override: false }),
    resetTheme() {
      return {};
    },
  },
});

export const {
  setTheme,
  resetTheme,
  setBannerImage,
  setLogoImage,
  changeColor,
  setHeaderVariant,
} = groupCategoryThemeSlice.actions;
export default groupCategoryThemeSlice.reducer;
