import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get } from "lodash";
import classnames from "classnames";

import { currentAppUrlSelector } from "selectors/navigation";
import GenericValueList from "./helpers/GenericValueList";
import { stringify } from "query-string";
import SharedTag from "components/shared/Tag";

export const tagFilterUrl = ({ name: q }) => `/search?${stringify({ q })}`;
export const TagUrlContext = createContext(tagFilterUrl);

function Tag({ value, options, property }) {
  const tagUrl = useContext(TagUrlContext);
  const showAsLink = get(options, "showAsLink") || false;

  return (
    <GenericValueList
      values={value}
      listStyle="flex flex-col gap-2"
      listItemStyle={classnames({ label: !showAsLink, link: showAsLink })}
      component={(tag) =>
        showAsLink ? (
          <a href={tagUrl({ ...tag, property })}>#{tag.name}</a>
        ) : (
          <SharedTag name={tag.name} href={tagUrl({ ...tag, property })} />
        )
      }
    />
  );
}

Tag.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  appUrl: PropTypes.string,
  options: PropTypes.shape({ showAsLink: PropTypes.bool }),
  property: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

export default connect((state) => ({
  appUrl: currentAppUrlSelector(state),
}))(Tag);
