import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import {
  map,
  get,
  find,
  isArray,
  isNil,
  castArray,
  flatMap,
  isEmpty,
  reject,
  includes,
  without,
  uniq,
} from "lodash";
import Select from "react-select";

import styles from "./editFields.module.css";
import FieldError from "components/appCreator/items/form/FieldError";

// Extract the value prop from the selected react-select value
// { value: "dr", label: "Dr." } => "dr"
// [{ value: "dr", label: "Dr." }] => ["dr"]
const normalizeValue = (value, multiple) => {
  if (value === null) return [];
  if (isArray(value)) return flatMap(value, normalizeValue);
  return multiple ? [get(value, "value")] : get(value, "value");
};

const createFormatValue = (options) =>
  // Map option identifiers, like ["dr"], to their options
  // used in react-select, like [{ value: "dr", label: "Dr." }]
  function formatValue(value) {
    if (value === null) return [];
    if (isArray(value)) return map(value, formatValue);

    return find(options, { value });
  };

export function SelectAdapter({
  input,
  meta: { error },
  options,
  multiple,
  required,
  id,
  disabled,
  className,
  placeholder,
}) {
  return (
    <Fragment>
      <Select
        {...input}
        // workaround for mobile,
        // see ticket #9930 or issue https://github.com/JedWatson/react-select/issues/2692#issuecomment-395743446
        onBlur={(e) => e.preventDefault()}
        className={className}
        options={reject(options, (option) => isEmpty(option.value))}
        isMulti={multiple}
        isClearable={!required}
        isDisabled={disabled}
        inputId={id}
        placeholder={
          placeholder || I18n.t("js.apps.properties.relation.placeholder")
        }
        noOptionsMessage={() => I18n.t("plugins.select2.no_match")}
        classNamePrefix={"Select"}
        styles={{ menu: (base) => ({ ...base, zIndex: 999 }) }}
      />
      {error && <FieldError error={error} />}
    </Fragment>
  );
}

SelectAdapter.propTypes = {
  input: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  multiple: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({ error: FieldError.propTypes.error }),
};

function CheckboxRadioAdapter({
  input,
  meta: { error },
  options,
  multiple,
  disabled,
  required,
}) {
  const type = multiple ? "checkbox" : "radio";
  const value = castArray(input.value);

  const onChange = (e) => {
    if (!multiple) return input.onChange(e.target.dataset.value);

    if (e.target.checked) {
      input.onChange(uniq([...value, e.target.dataset.value]));
    } else {
      input.onChange(without(value, e.target.dataset.value));
    }
  };

  const finalOptions =
    !multiple && !required
      ? [
          {
            value: "-1",
            label: I18n.t("js.apps.properties.selection.no_selection"),
          },
          ...options,
        ]
      : options;

  return map(finalOptions, (option, i) => (
    <Fragment key={i}>
      {isEmpty(option.value) ? (
        <h5>{option.label}</h5>
      ) : (
        <label key={option.value} className={type}>
          <input
            type={type}
            checked={includes(value, option.value)}
            data-value={option.value}
            onChange={onChange}
            disabled={disabled}
          />
          {option.label}
        </label>
      )}
      {error && <FieldError error={error} />}
    </Fragment>
  ));
}

CheckboxRadioAdapter.propTypes = SelectAdapter.propTypes;

function SelectionField({
  name,
  required,
  options,
  multiple,
  disabled,
  select: rawSelect,
  id,
  placeholder,
}) {
  // select is default true
  const select = isNil(rawSelect) ? true : rawSelect;

  return (
    <Field
      component={select ? SelectAdapter : CheckboxRadioAdapter}
      name={name}
      required={required}
      multiple={multiple}
      options={options}
      disabled={disabled}
      placeholder={placeholder}
      normalize={(value) => (select ? normalizeValue(value, multiple) : value)}
      format={select ? createFormatValue(options) : null}
      id={id}
      props={{ className: `${styles.Selection} property-${name} flex-1` }}
    />
  );
}

SelectionField.propTypes = {
  name: PropTypes.string.isRequired,
  select: PropTypes.bool,
  ...SelectAdapter.propTypes,
};

export default SelectionField;
