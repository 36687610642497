// Customized parser for emphasis (bold / italic)
// **this is bold**
// *this is italic*

import { isAlphaNum } from "components/shared/markdownEditor/parserPlugins/helpers";

// parse sequence of emphasis markers,
// "start" should point at a valid marker
const scanDelimiters = (state, start) => {
  let pos = start;
  let lastChar, nextChar, count;
  let canOpen = true;
  let canClose = true;

  const max = state.posMax;
  const marker = state.src.charCodeAt(start);

  lastChar = start > 0 ? state.src.charCodeAt(start - 1) : -1;

  while (pos < max && state.src.charCodeAt(pos) === marker) {
    pos++;
  }
  if (pos >= max) {
    canOpen = false;
  }
  count = pos - start;

  if (count >= 4) {
    // sequence of four or more unescaped markers can't start/end an emphasis
    canOpen = canClose = false;
  } else {
    nextChar = pos < max ? state.src.charCodeAt(pos) : -1;

    // check whitespace conditions
    if (nextChar === 0x20 || nextChar === 0x0a) {
      canOpen = false;
    }
    if (lastChar === 0x20 || lastChar === 0x0a) {
      canClose = false;
    }

    if (marker === 0x2a /* * */) {
      // check if we aren't inside the word
      if (isAlphaNum(lastChar)) {
        canOpen = false;
      }
      if (isAlphaNum(nextChar)) {
        canClose = false;
      }
    }
  }

  return {
    canOpen: canOpen,
    canClose: canClose,
    delimiters: count,
  };
};

const emphasis = (state, silent) => {
  let startCount, count, found, oldCount, newCount, stack, res;
  const max = state.posMax;
  const start = state.pos;
  const marker = state.src.charCodeAt(start);

  if (marker !== 0x2a /* * */) {
    return false;
  }
  if (silent) {
    return false;
  } // don't run any pairs in validation mode

  res = scanDelimiters(state, start);
  startCount = res.delimiters;
  if (!res.canOpen) {
    state.pos += startCount;
    if (!silent) {
      state.pending += state.src.slice(start, state.pos);
    }
    return true;
  }

  if (state.level >= state.options.maxNesting) {
    return false;
  }

  state.pos = start + startCount;
  stack = [startCount];

  while (state.pos < max) {
    if (state.src.charCodeAt(state.pos) === marker) {
      res = scanDelimiters(state, state.pos);
      count = res.delimiters;
      if (res.canClose) {
        oldCount = stack.pop();
        newCount = count;

        while (oldCount !== newCount) {
          if (newCount < oldCount) {
            stack.push(oldCount - newCount);
            break;
          }

          // assert(newCount > oldCount)
          newCount -= oldCount;

          if (stack.length === 0) {
            break;
          }
          state.pos += oldCount;
          oldCount = stack.pop();
        }

        if (stack.length === 0) {
          startCount = oldCount;
          found = true;
          break;
        }
        state.pos += count;
        continue;
      }

      if (res.canOpen) {
        stack.push(count);
      }
      state.pos += count;
      continue;
    }

    state.parser.skipToken(state);
  }

  if (!found) {
    // parser failed to find ending tag, so it's not valid emphasis
    state.pos = start;
    return false;
  }

  // found!
  state.posMax = state.pos;
  state.pos = start + startCount;

  if (!silent) {
    if (startCount === 2 || startCount === 3) {
      state.push({ type: "strong_open", level: state.level++ });
    }
    if (startCount === 1 || startCount === 3) {
      state.push({ type: "em_open", level: state.level++ });
    }

    state.parser.tokenize(state);

    if (startCount === 1 || startCount === 3) {
      state.push({ type: "em_close", level: --state.level });
    }
    if (startCount === 2 || startCount === 3) {
      state.push({ type: "strong_close", level: --state.level });
    }
  }

  state.pos = state.posMax + startCount;
  state.posMax = max;
  return true;
};

const customizedEmphasisParser = (md) => {
  md.inline.ruler.at("emphasis", emphasis);
};

export default customizedEmphasisParser;
