import React, { Component, Fragment, createRef } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { get } from "lodash";

import styles from "./editFields.module.css";
import FieldError from "components/appCreator/items/form/FieldError";
import loadAndConfigureCKEditor from "../../../../helpers/ckeditor/loadAndConfigureCKEditor";

class CKEditorAdpater extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
  }

  async componentDidMount() {
    const CKEDITOR = await loadAndConfigureCKEditor();

    this.lastValue = this.props.input.value;
    this.editor = CKEDITOR.replace(this.ref.current, {
      codeSnippet_theme: "github",
      bodyClass: styles.Html,
    });

    this.editor.on("change", () => {
      this.editor.updateElement();
      this.lastValue = this.ref.current.value;
      this.props.input.onChange(this.lastValue);
    });
  }

  componentDidUpdate() {
    if (!this.editor) return;

    const newValue = this.props.input.value;

    if (newValue !== this.lastValue) {
      this.lastValue = newValue;
      this.editor.setData(newValue);
    }
  }

  componentWillUnmount() {
    if (!this.editor) return;

    this.editor.destroy();
  }

  render() {
    const error = get(this.props, ["meta", "error"]);
    return (
      <Fragment>
        <textarea
          ref={this.ref}
          id={this.props.id}
          value={this.props.input.value}
          onChange={this.props.input.onChange}
        />
        {error && <FieldError error={error} />}
      </Fragment>
    );
  }
}
CKEditorAdpater.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  id: PropTypes.string,
  meta: PropTypes.shape({ error: FieldError.propTypes.error }),
};

function HtmlField({ name, required, disabled, id }) {
  return (
    <Field
      name={name}
      required={required}
      component={CKEditorAdpater}
      disabled={disabled}
      id={id}
    />
  );
}
HtmlField.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  content: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

export default HtmlField;
