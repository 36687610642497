import { createApiAction } from "helpers/api";
import { stringify } from "query-string";
import { isNil, omitBy } from "lodash";

export const NAMESPACE = "tags";

export const fetchTags = createApiAction({
  method: "GET",
  endpoint: ({ q, tagParents, excludeNew }) =>
    `/tags/find?${stringify(
      omitBy({ q, tag_parents: tagParents, exclude_new: excludeNew }, isNil),
    )}`,
  baseType: `${NAMESPACE}/SEARCH`,
});
