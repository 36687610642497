import React, { MouseEventHandler } from "react";
import classnames from "classnames";

export type ModalActionsProps = {
  closeModal?: MouseEventHandler<HTMLAnchorElement>;
  submitText?: string;
  submit?: MouseEventHandler<HTMLAnchorElement>;
  modalSubmitActionClassName?: string;
};

function ModalActions({
  closeModal,
  submitText,
  submit,
  modalSubmitActionClassName,
}: ModalActionsProps) {
  return (
    <div className="form-actions modal-footer">
      <a
        href="#"
        onClick={closeModal}
        className="btn btn-link"
        data-bs-dismiss="modal"
      >
        {I18n.t("js.cancel")}
      </a>
      <a
        href="#"
        onClick={submit}
        className={classnames("btn btn-primary", modalSubmitActionClassName)}
      >
        {submitText ? I18n.t(submitText) : ""}
      </a>
    </div>
  );
}

export default ModalActions;
