import React from "react";

import FolderIcon from "./FolderIcon";
import PropTypes from "prop-types";
import classNames from "classnames";

import FolderInfo from "./FolderInfo";

const FolderRow = React.memo(function FolderRow({
  id,
  name,
  type,
  handleNavigate,
  extraColumns,
  profile = {},
  navigateDisabled,
  noExtraColspan,
  className,
  striped,
  context,
}) {
  return (
    <tr
      className={classNames("folder-row", className, {
        striped,
      })}
    >
      <td className="col-icon folder-icon p-2 text-center" width="1%">
        <FolderIcon type={type} />
      </td>
      <FolderInfo
        id={id}
        name={name}
        handleNavigate={handleNavigate}
        extraColumns={extraColumns}
        profile={profile}
        context={context}
        navigateDisabled={navigateDisabled}
        noExtraColspan={noExtraColspan}
      />
    </tr>
  );
});

FolderRow.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  handleNavigate: PropTypes.func,
  noExtraColspan: PropTypes.bool,
  extraColumns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.func.isRequired,
      Data: PropTypes.func.isRequired,
    }),
  ),
  profile: PropTypes.object,
  navigateDisabled: PropTypes.bool,
  className: PropTypes.string,
  striped: PropTypes.bool,
};

export const FolderRowPropTypes = FolderRow.propTypes;
export default FolderRow;
