import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import FolderIcon from "./FolderIcon";
import FolderInfo from "./FolderInfo";
import { FolderRowPropTypes } from "./FolderRow";

const SelectableFolderRow = React.memo(function SelectableFolderRow({
  id,
  name,
  type,
  handleNavigate,
  extraColumns,
  profile = {},
  isSelected,
  onSelectFolder,
  disableSelection,
  navigateDisabled,
  noExtraColspan,
  className,
  showCheckbox,
  onlyShowName,
  context,
}) {
  const [hover, setHover] = useState(false);
  const active = hover || isSelected;

  return (
    <tr
      className={classNames("folder-row", className, {
        ["hover:bg-primary/25 cursor-pointer"]: !disableSelection || active,
      })}
      onMouseOver={!disableSelection ? () => setHover(true) : null}
      onMouseLeave={!disableSelection ? () => setHover(false) : null}
      onClick={!disableSelection ? () => onSelectFolder(id, true) : null}
    >
      <td className="col-checkbox p-2 text-center">
        {(active || showCheckbox) && !disableSelection ? (
          <input
            type="checkbox"
            value={id}
            checked={isSelected}
            onChange={() => onSelectFolder(id)}
          />
        ) : null}
      </td>
      <td className="col-icon folder-icon text-center" width="1%">
        <FolderIcon type={type} />
      </td>
      <FolderInfo
        id={id}
        name={name}
        handleNavigate={handleNavigate}
        extraColumns={onlyShowName ? null : extraColumns}
        profile={profile}
        navigateDisabled={navigateDisabled}
        context={context}
        noExtraColspan={onlyShowName ? true : noExtraColspan}
      />
    </tr>
  );
});

SelectableFolderRow.propTypes = {
  ...FolderRowPropTypes,
  isSelected: PropTypes.bool,
  onSelectFolder: PropTypes.func,
  enableSelection: PropTypes.bool,
  showCheckbox: PropTypes.bool,
};

export default SelectableFolderRow;
