import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";

import { selectFolder } from "actions/files";
import { currentFolderMetaSelector } from "selectors/folders";
import Breadcrumb from "../Breadcrumb";
import { useFetchFolder } from "../api";

function useFolderResource({ currentRootFolder }) {
  useEffect(() => {
    dispatch(
      selectFolder({
        id: currentFolderId,
        namespace: currentFolderNamespace,
      }),
    );
  }, [currentRootFolder.id]);

  const { id: currentRootFolderId, namespace: currentRootFolderNamespace } =
    currentRootFolder;
  const currentFolderMeta = useSelector(currentFolderMetaSelector);
  const currentFolderId = get(currentFolderMeta, ["id"], currentRootFolderId);
  const currentFolderNamespace = get(
    currentFolderMeta,
    ["namespace"],
    currentRootFolderNamespace,
  );

  const { data: currentFolder, isLoading: loading } = useFetchFolder(
    currentFolderId,
    currentFolderNamespace,
  );

  const dispatch = useDispatch();

  const handleNavigate = ({ id, namespace }) => {
    dispatch(selectFolder({ id, namespace }));
  };

  const breadcrumb = (
    <Breadcrumb folder={currentFolder} handleNavigate={handleNavigate} />
  );

  return { loading, currentFolder, handleNavigate, breadcrumb };
}

export default useFolderResource;
