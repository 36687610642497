import React, { useState, useCallback } from "react";
import createMentionPlugin from "@draft-js-plugins/mention";
import { showNameWithProfileField } from "components/memberships/MemberNameWithAdditions";
import DefaultEntryComponent from "./defaultEntryComponent";

interface MentionTypes {
  className: string;
  children: React.ReactNode;
}

function Mention({ className, children }: MentionTypes) {
  return (
    <div className={className + " inline-mention-container"}>@{children}</div>
  );
}

interface MentionEntryTypes {
  className?: string;
}

function MentionEntry({ className, ...props }: MentionEntryTypes) {
  return (
    <DefaultEntryComponent className={`${className} suggestion`} {...props} />
  );
}

const inlineMentionPlugin = createMentionPlugin({
  mentionTrigger: "@",
  entityMutability: "IMMUTABLE",
  mentionComponent: Mention,
  supportWhitespace: true,
});

function InlineMentionSuggestions() {
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);

  return (
    <inlineMentionPlugin.MentionSuggestions
      onSearchChange={search}
      open={open}
      onOpenChange={onOpenChange}
      suggestions={suggestions}
      entryComponent={MentionEntry}
    />
  );

  async function search({ value }) {
    const response = await $.ajax({
      url: `${Tixxt.currentNetwork.get("url")}/members`,
      type: "get",
      dataType: "json",
      data: {
        q: `${value}*`,
      },
      contentType: "application/json; charset=utf-8",
    });

    setSuggestions(
      response.map((m) => ({
        id: m.id,
        name: showNameWithProfileField({
          name: m.displayName,
          detectionProfileField: m.detection_profile_field,
          isExternal: m.is_external,
        }),
        avatar: m.images.small,
      })),
    );
  }
}

export default function createInlineMentionPlugin() {
  return { inlineMentionPlugin, InlineMentionSuggestions };
}
