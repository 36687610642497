import React from "react";
import PropTypes from "prop-types";
import { isEmpty, compact } from "lodash";

import styles from "./showFields.module.css";
// TODO: optional show map
function Address({ value = {}, property = {} }) {
  return (
    <address className={styles.Address}>
      {property.with_name && !isEmpty(value.name) ? (
        <span className="name">{value.name}</span>
      ) : null}
      <span className="street-number">
        {compact([value.street, value.number]).join(" ")}
      </span>
      <span className="zip-city">
        {compact([value.zip, value.city]).join(" ")}
      </span>
      {!isEmpty(value.country) ? (
        <span className="country">{value.country}</span>
      ) : null}
    </address>
  );
}
Address.propTypes = {
  value: PropTypes.shape({
    name: PropTypes.string,
    street: PropTypes.string,
    number: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
  property: PropTypes.shape({
    with_name: PropTypes.bool,
  }),
};

export default Address;
