import React from "react";
import PropTypes from "prop-types";
import { compose, withHandlers } from "recompose";
import { find, get } from "lodash";

function CodeBlockLanguageSelect({
  options,
  selectedValue,
  createChangeLanguageHandler,
}) {
  return (
    <div className="code-block-language-select">
      {selectedValue ? (
        <small>
          {get(find(options, ["value", selectedValue]), "label") ||
            selectedValue}
        </small>
      ) : null}
      <span className="dropdown">
        <a className="dropdown-toggle" data-bs-toggle="dropdown" href="#">
          <i className="fa fa-ellipsis-h" />
        </a>
        <ul className="context-menu dropdown-menu left">
          {options.map(({ label, value }) => (
            <li
              key={value}
              className={
                value === selectedValue ||
                (value === "plain" && selectedValue === undefined)
                  ? "active"
                  : ""
              }
            >
              <a href="#" onClick={createChangeLanguageHandler(value)}>
                {label}
              </a>
            </li>
          ))}
        </ul>
      </span>
    </div>
  );
}

CodeBlockLanguageSelect.propTypes = {
  options: PropTypes.array,
  selectedValue: PropTypes.string,
  createChangeLanguageHandler: PropTypes.func,
};

export default compose(
  withHandlers({
    createChangeLanguageHandler:
      ({ onChange }) =>
      (newValue) =>
      (clickEvent) => {
        clickEvent.preventDefault();
        clickEvent.stopPropagation();

        newValue = newValue === "plain" ? undefined : newValue;
        // call onChange with a fake event, cause the onChange handle needs an dom event
        onChange({
          currentTarget: { value: newValue },
        });
      },
  }),
)(CodeBlockLanguageSelect);
